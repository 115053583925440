import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import StyledContentWrapper from "../../styledComponents/ContentWrapper";
import MainImageWrapper from "../MainImageWrapper";
import GeneralErrorIcon from "../svgicons/GeneralErrorIcon";
import SmallPhoneIcon from "../svgicons/SmallPhoneIcon";
import { CALL_CENTAR_PHONE, phoneLink } from "../../constants/constants";
import OneButtonHolder from "../buttons/one-button-holder";
import React from "react";

const GeneralError = () => {
  const theme = useTheme();
  const styles = {
    image: {
      width: "256px",
      height: "170px",
      [theme.breakpoints.up("lg")]: {
        width: "366px",
        height: "244px",
      },
    },
    title: {
      margin: "0 0 30px 0",
    },
    mobileHolder: {
      [theme.breakpoints.down("md")]: {
        paddingBottom: "90px",
      },
    },
    phoneNumber: {
      alignItems: "center",
      justifyContent: "center",
      marginTop: "30px",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },
      "& .number": {
        color: theme.palette.primary.main,
        paddingLeft: "10px",
        fontSize: "25px",
        fontWeight: "bold",
      },
    },
    buttonHolder: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  };
  return (
    <StyledContentWrapper>
      <MainImageWrapper
        ImageComponent={GeneralErrorIcon}
        style={{ width: "256px", height: "170px" }}
      />
      <Box className="content-wrapper" sx={{ ...styles.mobileHolder }}>
        <Typography variant="h4" className="title" sx={{ ...styles.title }}>
          Възникна грешка!
        </Typography>
        <Typography variant="body1" className="body" sx={{ maxWidth: "450px" }}>
          Моля, опитайте отново или потърсете съдействие на телефон:
        </Typography>
        <Typography variant="body1" sx={{ ...styles.phoneNumber }}>
          <SmallPhoneIcon />
          <a
            style={{ textDecoration: "none" }}
            className="number"
            href={phoneLink}
          >
            {CALL_CENTAR_PHONE}
          </a>
        </Typography>
        <OneButtonHolder
          label="Опитайте отново"
          onClickContinue={() => {
            window.location.reload();
          }}
        />
      </Box>
    </StyledContentWrapper>
  );
};

export default GeneralError;
