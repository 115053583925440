import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import LoadingDocumentBox from "../components/documents-loading/LoadingDocumentBox";
import { Button, Grid } from "@mui/material";
import theme from "../theme/theme";
import StyledContentWrapper from "../styledComponents/ContentWrapper";
import { Box } from "@mui/system";
import CircularMultiSpinner from "./svgicons/CircularMultiSpinner";
import { DOCUMENT_TYPES, ROUTE_PATHS, STATUS } from "../constants/constants";
import { useResizeDetector } from "react-resize-detector";
import BackButtonMobile from "./buttons/back-button-mobile";
import ArrowBack from "./svgicons/ArrowBack";
import STEP_ID from "../helpers/steps";
import useStepData from "../hooks/useStepData";

const styles = {
  pdfContainer: {
    margin: "auto",
    "&>div": {
      margin: "auto",
    },
  },
  button: {
    root: {
      color: theme.palette.others.white,
    },
  },
};

const ViewPage = ({
  statusSelector,
  urlSelector,
  getAction,
  title,
  downloadFileName,
  viewNum,
}) => {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const status = useSelector(statusSelector);
  const url = useSelector(urlSelector);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(STATUS.IDLE);
  const { width, ref } = useResizeDetector();
  const { currentStep } = useStepData();

  const handleDownload = useCallback(() => {
    saveAs(url, downloadFileName);
  }, [url, downloadFileName]);

  useEffect(() => {
    let timeout;
    if (status === STATUS.IDLE) {
      dispatch(getAction());
    }
    if (status === STATUS.SUCCEEDED) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setLoading(status);
        clearTimeout(timeout);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [status, getAction]);

  //temporary added
  const goBack = useCallback(() => {
    switch (currentStep) {
      case STEP_ID.LoanPreviewAlternativeCodebtor:
        navigate(ROUTE_PATHS.CreditOverview, { replace: true });
        break;
      case STEP_ID.Documents:
        navigate(ROUTE_PATHS.FinaliseOfferDocuments, { replace: true });
        break;
      case STEP_ID.RevolvingDocuments:
        navigate(ROUTE_PATHS.FinaliseOfferDocuments, { replace: true });
        break;
      default:
        break;
    }
  }, [navigate, currentStep]);

  const handleDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const handleSend = () => {
    switch (viewNum) {
      case DOCUMENT_TYPES.DECLARATIONS:
        navigate(ROUTE_PATHS.SendDeclarationsEmail, { replace: true });
        break;
      case DOCUMENT_TYPES.LOAN_APPLICATION:
        navigate(ROUTE_PATHS.SendLoanApplicationEmail, { replace: true });

        break;
      case DOCUMENT_TYPES.LOAN_CONTRACT:
        navigate(ROUTE_PATHS.SendLoanContractEmail, { replace: true });

        break;
      case DOCUMENT_TYPES.OVERDRAFT_ANEX:
        navigate(ROUTE_PATHS.SendOverdraftAnexEmail, { replace: true });

        break;
      default:
        break;
    }
  };

  let content;
  if (loading !== STATUS.SUCCEEDED) {
    content = <LoadingDocumentBox title={title} />;
  } else if (loading === STATUS.SUCCEEDED) {
    content = (
      <StyledContentWrapper>
        <BackButtonMobile onClickBack={goBack} />
        <Box
          className="page-content-wrapper"
          sx={{ marginTop: "15px", marginBottom: "-56px" }}
        >
          <div ref={ref}>
            <Document
              file={url}
              onLoadSuccess={handleDocumentLoadSuccess}
              loading={
                <Box sx={{ margin: "0 30px", width: "100%" }}>
                  <CircularMultiSpinner />
                </Box>
              }
            >
              <Box
                sx={{
                  height: "calc(100vh - 500px)",
                  minHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    renderMode="canvas"
                    renderTextLayer={false}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    sx={styles.pdfContainer}
                    renderAnnotationLayer={false}
                    width={width - 20}
                  />
                ))}
              </Box>
            </Document>
          </div>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={1}
            className="only_mobile"
          >
            {(viewNum === DOCUMENT_TYPES.LOAN_APPLICATION ||
              viewNum === DOCUMENT_TYPES.LOAN_CONTRACT ||
              viewNum === DOCUMENT_TYPES.DECLARATIONS) && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    marginBottom: "5px",
                    backgroundColor: "#EEF7EB",
                  }}
                  onClick={handleSend}
                >
                  Изпрати
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
                onClick={handleDownload}
              >
                Свали
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}
            className="only_desktop"
          >
            <Grid item xs={4}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  marginBottom: "5px",
                  width: "auto",
                }}
                onClick={goBack}
                className="back-btn"
              >
                <ArrowBack />
                Назад
              </Button>
            </Grid>
            {(viewNum === DOCUMENT_TYPES.LOAN_APPLICATION ||
              viewNum === DOCUMENT_TYPES.LOAN_CONTRACT ||
              viewNum === DOCUMENT_TYPES.DECLARATIONS ||
              viewNum === DOCUMENT_TYPES.OVERDRAFT_ANEX) && (
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    marginBottom: "5px",
                    backgroundColor: "#EEF7EB",
                  }}
                  onClick={handleSend}
                >
                  Изпрати
                </Button>
              </Grid>
            )}
            <Grid item xs={4}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  marginBottom: "5px",
                }}
                onClick={handleDownload}
              >
                Свали
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledContentWrapper>
    );
  }

  return <>{content}</>;
};

export default ViewPage;
