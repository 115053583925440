import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/constants";

import { setStep, setStepRequiresRefresh } from "../step/stepSlice";
import { saveDeclarationsRequest, saveCodebtorDeclarationRequest } from "./declarationsApi";

export const saveDeclarations = createAsyncThunk(
  "loan/declarations",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        declaration1: true,
        declaration2: true,
        declaration3: true,
        declaration4: true,
      };

      const response = await saveDeclarationsRequest(data);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.result) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const saveCodebtorDeclaration = createAsyncThunk(
  "loan/declaration-consent",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await saveCodebtorDeclarationRequest();
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.result) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

/// Default initial state object
const initialState = {
  declarationsStatus: STATUS.IDLE,
  codebtorDeclarationStatus: STATUS.IDLE,
  error: null,
};

/// Calculator slice
export const declarationsSlice = createSlice({
  name: "declarations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveDeclarations.pending, (state, action) => {
        state.declarationsStatus = STATUS.LOADING;
      })
      .addCase(saveDeclarations.fulfilled, (state, action) => {
        state.declarationsStatus = STATUS.SUCCEEDED;
      })
      .addCase(saveDeclarations.rejected, (state, action) => {
        state.declarationsStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(saveCodebtorDeclaration.pending, (state, action) => {
        state.codebtorDeclarationStatus = STATUS.LOADING;
      })
      .addCase(saveCodebtorDeclaration.fulfilled, (state, action) => {
        state.codebtorDeclarationStatus = STATUS.SUCCEEDED;
      })
      .addCase(saveCodebtorDeclaration.rejected, (state, action) => {
        state.codebtorDeclarationStatus = STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});

export default declarationsSlice.reducer;
