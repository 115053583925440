import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import MainImageWrapper from "../../components/MainImageWrapper";
import DocumentsWaitIcon from "../../components/svgicons/DocumentsWaitIcon";
import React from "react";
import StyledContentWrapperNoButton from "../../styledComponents/ContentWrapperNobutton";

const WaitingSecondOwnerScreen = () => {
  return (
    <StyledContentWrapperNoButton>
      <MainImageWrapper ImageComponent={DocumentsWaitIcon} />
      <Box className="content-wrapper">
        <Typography variant="body1" sx={{ maxWidth: "430px" }}>
          За да продължите към документите за кредит, е необходимо Вашият
          съдружник да последва изпратения му в ДСК Директ линк и да
          въведе/потвърди своите данни.
        </Typography>
      </Box>
    </StyledContentWrapperNoButton>
  );
};

export default WaitingSecondOwnerScreen;
