export function saveDeclarationsRequest(declarationsRequest) {
  const saveDeclarationsRequestOptions = {
    method: "POST",
    body: JSON.stringify(declarationsRequest),
  };

  const declarationsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/declarations`;
  return fetch(declarationsUrl, saveDeclarationsRequestOptions);
}

export function saveCodebtorDeclarationRequest() {
  const saveCodebtorDeclarationRequestOption = {
    method: "POST"
  };

  const declarationConsentUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/declaration-consent`;
  return fetch(declarationConsentUrl, saveCodebtorDeclarationRequestOption);
}
