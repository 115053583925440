import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import styles from "./header-styles";

import Box from "@mui/material/Container";
import TopBarLogoIcon from "../svgicons/TopBarLogoIcon";
import TopBarLogoIconDesktop from "../svgicons/TopBarLogoIconDesktop";
import TopBarImageDesktop from "../svgicons/TopBarImageDesktop";
import { CALL_CENTAR_PHONE } from "../../constants/constants";
import SmallWhitePhoneIcon from "../svgicons/SmallWhitePhoneIcon";
import useStepData from "../../hooks/useStepData";

function Header() {
  const { currentStep } = useStepData();
  return (
    <>
      <Box sx={{ ...styles.mobile }}>
        <TopBarLogoIcon />
      </Box>
      <Container sx={{ ...styles.desktop.container }}>
        <div style={{ position: "relative" }}>
          <TopBarImageDesktop />
        </div>
        <Box sx={{ ...styles.desktop.content }}>
          <TopBarLogoIconDesktop />
          {currentStep < 10000 && (
            <Typography
              variant="h1"
              sx={{
                ...styles.desktop.title,
              }}
            >
              Кредит в 4 лесни стъпки
            </Typography>
          )}
          <Box
            sx={{
              ...styles.desktop.number,
            }}
          >
            Call center:
            <Typography variant="span" sx={{ ...styles.desktop.phoneIcon }}>
              <SmallWhitePhoneIcon sx={{ fontSize: "1rem" }} />
            </Typography>
            {CALL_CENTAR_PHONE}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Header;
