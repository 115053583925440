/// Константа за Slider от тип LoanAmount
export const LoanAmount = "LoanAmount";
/// Константа за Slider от тип LoanDuration
export const LoanDuration = "LoanDuration";
/// константа за Slider от тип ExpiryRepaymnet.
export const ExpiryRepayment = "ExpiryRepayment";

/// Константи за Highlight за форма FinancingHighLight
export const HighlightChooseLoan = "ChooseLoan";
export const HighlightVerifyIdentity = "VerifyIdentity";
export const HighlightLoanApplication = "LoanApplication";
export const HighlightReceiveOffer = "ReceiveOffer";

/// Константи за препращане между страници за зареждане на документи.
export const LoanContractHistoryState = "LoanContract";
export const LoanApplicationHistoryState = "LoanApplication";
export const DeclarationsHistoryState = "Declarations";

export const SliderGridColumns = 8;
export const SliderGridSpacing = 1;
