import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import STEP_ID from "../helpers/steps";
import useApplicationData from "../hooks/useApplicationData";
import useInterval from "../hooks/useInterval";
import useStepData from "../hooks/useStepData";
import { ROUTE_PATHS, WAITING_INTERVAL } from "../constants/constants";
import {
  getLoanStep,
  setWaitingMode,
  setWaitModeNumFails,
} from "../providers/step/stepSlice";

const NavigationComponent = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useApplicationData();
  const { currentStep, journeyStepRequiresRefresh } = useStepData();
  const [initialStep, setInitialStep] = useState();
  const dispatch = useDispatch();

  const interval = useInterval(
    () => {
      dispatch(getLoanStep());
    },
    initialStep !== currentStep ? null : WAITING_INTERVAL
  );

  useEffect(() => {
    if (journeyStepRequiresRefresh) {
      setInitialStep(currentStep);
      dispatch(setWaitingMode(true));
      return () => {
        clearInterval(interval);
        dispatch(setWaitingMode(false));
        dispatch(setWaitModeNumFails(0));
      };
    }
  }, [journeyStepRequiresRefresh, currentStep, dispatch, interval]);

  const gotoPage = useCallback((step) => {
    switch (step) {
      case STEP_ID.LandingPage:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.LandingPageAlternativeBeforeLoanPreview:
        navigate(ROUTE_PATHS.LandingPage, {
          replace: true,
        });
        break;
      case STEP_ID.LandingPageAlternativeAfterLoanPreview:
        navigate(ROUTE_PATHS.LandingPage, {
          replace: true,
        });
        break;
      case STEP_ID.LandingPageAlternativeAfterLoanPreviewCodebtor:
        navigate(ROUTE_PATHS.LandingPage, {
          replace: true,
        });
        break;
      case STEP_ID.LandingPageAlternativePartnerNoRepresentative:
        navigate(ROUTE_PATHS.LandingPage, {
          replace: true,
        });
        break;
      case STEP_ID.LandingPageAlternativePartnerCodebtor:
        navigate(ROUTE_PATHS.LandingPage, {
          replace: true,
        });
        break;
      case STEP_ID.LandingPageAlternativeAfterLoanPreviewCodebtorMandatory:
        navigate(
          ROUTE_PATHS.LandingPage,
          { replace: true }
        );
        break;
      case STEP_ID.TermLoanLandingPage:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.TermLoanLandingPageCodebtor:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.TermLoanLandingPageCodebtorMandatory:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.TermLoanLandingPagePartnerLoanSuccess:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.CreditLineLandingPagePartner:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.CreditLineLandingPage:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.CreditLineLandingPageCodebtor:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.CreditLineLandingPageCodebtorMandatory:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.CreditLineLoanParameters:
        navigate(ROUTE_PATHS.CalculatorCreditLine, { replace: true });
        break;
      case STEP_ID.LoanParameters:
        navigate(ROUTE_PATHS.Calculator, { replace: true });
        break;
      case STEP_ID.TermLoanParameters:
        navigate(ROUTE_PATHS.CalculatorTermLoan, { replace: true });
        break;
      case STEP_ID.StandardEditLoanParameters:
        navigate(ROUTE_PATHS.StandardEditLoanParameters, { replace: true });
        break;
      case STEP_ID.Declarations:
        navigate(ROUTE_PATHS.AcceptAgreement, { replace: true });
        break;
      case STEP_ID.TermLoanDeclarations:
        navigate(ROUTE_PATHS.AcceptAgreement, { replace: true });
        break;
      case STEP_ID.CreditLineDeclarations:
        navigate(ROUTE_PATHS.AcceptAgreement, { replace: true });
        break;
      case STEP_ID.CodebtorDeclaration:
        navigate(ROUTE_PATHS.AcceptAgreementCodebtor, { replace: true });
        break;
      case STEP_ID.CCRValidationsWait:
        navigate(ROUTE_PATHS.CCRValidationsLoading, { replace: true });
        break;
      case STEP_ID.BankAccountsValidationWait:
        navigate(ROUTE_PATHS.CCRValidationsLoading, { replace: true });
        break;
      case STEP_ID.CCRValidationsDropped:
        navigate(ROUTE_PATHS.CreditNotApproved, { replace: true });
        break;
      case STEP_ID.CodebtorCCRValidationsDropped:
        navigate(ROUTE_PATHS.CodebtorCreditNotApproved, { replace: true });
        break;
      case STEP_ID.BankAccounts:
        navigate(ROUTE_PATHS.AcceptAgreementApproved, { replace: true });
        break;
      case STEP_ID.AccountValidationDropped:
        navigate(ROUTE_PATHS.OnlineValidationsDroppedHold, { replace: true });
        break;
      case STEP_ID.LoanPreview:
        navigate(ROUTE_PATHS.CreditOverview, { replace: true });
        break;
      case STEP_ID.LoanPreviewAlternative:
        navigate(ROUTE_PATHS.CreditOverview, { replace: true });
        break;
      case STEP_ID.LoanPreviewAlternativeCodebtor:
        navigate(ROUTE_PATHS.CreditOverview, { replace: true });
        break;
      case STEP_ID.CreditLineLoanPreviewCodebtor:
        navigate(ROUTE_PATHS.CreditLineLoanPreviewCodebtor, { replace: true });
        break;
      case STEP_ID.TermLoanLoanPreview:
        navigate(ROUTE_PATHS.TermLoanCreditOverview, { replace: true });
        break;
      case STEP_ID.TermLoanLoanPreviewCodebtor:
        navigate(ROUTE_PATHS.TermLoanCreditOverview, { replace: true });
        break;
      case STEP_ID.TermLoanSuccessPreviewPartner:
        navigate(ROUTE_PATHS.TermLoanCreditOverview, { replace: true });
        break;
      case STEP_ID.CreditLineLoanPreview:
        navigate(ROUTE_PATHS.CreditLineLoanPreview, { replace: true });
        break;
      case STEP_ID.CreditLineLoanPreviewPartner:
        navigate(ROUTE_PATHS.CreditLineLoanPreview, { replace: true });
        break;
      case STEP_ID.CreditLineEditLoanParameters:
        navigate(ROUTE_PATHS.CreditLineEditLoanParameters, { replace: true });
        break;
      case STEP_ID.TermLoanEditLoanParameters:
        navigate(ROUTE_PATHS.TermLoanEditLoanParameters, { replace: true });
        break;
      case STEP_ID.Codebtors:
        navigate(ROUTE_PATHS.AddSecondOwner, { replace: true });
        break;
      case STEP_ID.CodebtorsMandatory:
        navigate(ROUTE_PATHS.SecondOwnerMandatory, { replace: true });
        break;
      case STEP_ID.OnlineValidationsDropped:
        navigate(ROUTE_PATHS.OverdarftOnlineValidationsDroppedCircumstances, { replace: true });
        break;
      case STEP_ID.ExistingOverdraftDropped:
        navigate(ROUTE_PATHS.ExistingOverdraftDropped, { replace: true });
        break;
      case STEP_ID.Documents:
        navigate(ROUTE_PATHS.FinaliseOfferDocuments, { replace: true });
        break;
      case STEP_ID.DocumentsParameters:
        navigate(ROUTE_PATHS.ProvidePhoneEmail, { replace: true });
        break;
      case STEP_ID.DocumentsWait:
        navigate(ROUTE_PATHS.WaitSecondOwner, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureParametersWait:
        navigate(ROUTE_PATHS.WaitOtherOwnerConfirmSigning, { replace: true });
        break;
      case STEP_ID.DocumentsGenerationFailure:
        navigate(ROUTE_PATHS.FinaliseOfferDocumentsDeclined, { replace: true });
        break;
      case STEP_ID.OnlineValidationsWait:
        navigate(ROUTE_PATHS.OnlineValidationsLoading, { replace: true });
        break;
      case STEP_ID.CodebtorCCRValidationsWait:
        navigate(ROUTE_PATHS.CodebtorCCRValidationsLoading, { replace: true });
        break;
      case STEP_ID.DocumentsSignature:
        navigate(ROUTE_PATHS.PreConfirmUserInfo, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureOTP:
        navigate(ROUTE_PATHS.EurotrustPhoneOtpValidation, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureOTPWait:
        navigate(ROUTE_PATHS.WaitFirstOwnerFinishSigning, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureWait:
        navigate(ROUTE_PATHS.DocumentSigningLoading, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureFailure:
        navigate(ROUTE_PATHS.FinaliseOfferDeclined, { replace: true });
        break;
      case STEP_ID.UnsuccessfulDocumentSigning:
        navigate(ROUTE_PATHS.UnsuccessfulDocumentSigning, { replace: true });
        break;
      case STEP_ID.DocumentsSignatureDeniedAlternative:
        navigate(ROUTE_PATHS.FinaliseOfferDeclinedSecondOwner, {
          replace: true,
        });
        break;
      case STEP_ID.DocumentsSignatureWaitAlternative:
        navigate(ROUTE_PATHS.DocumentSigningLoading, { replace: true });
        break;
      case STEP_ID.FinalPage:
        navigate(ROUTE_PATHS.FinaliseOfferSuccess, { replace: true });
        break;
      case STEP_ID.JourneyExpired:
        navigate(ROUTE_PATHS.JourneyExpireView, { replace: true });
        break;
      case STEP_ID.RevolvingLandingPage:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.RevolvingLandingPageCodebtor:
        navigate(ROUTE_PATHS.LandingPage, { replace: true });
        break;
      case STEP_ID.RevolvingOfferAcceptedLoanParametars:
        navigate(ROUTE_PATHS.RevolvingOfferAcceptedLoanParametars, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOfferDeclined:
        navigate(ROUTE_PATHS.RevolvingOfferDeclined, { replace: true });
        break;
      case STEP_ID.RevolvingOfferDeclinedAlternativeCodebtor:
        navigate(ROUTE_PATHS.RevolvingOfferDeclinedAlternativeCodebtor, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOfferAcceptedAlternativeCodebtor:
        navigate(ROUTE_PATHS.RevolvingOfferAcceptedAlternativeCodebtor, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingDocumentsParameters:
        navigate(ROUTE_PATHS.RevolvingDocumentsParameters, { replace: true });
        break;
      case STEP_ID.RevolvingDocuments:
        navigate(ROUTE_PATHS.RevolvingDocuments, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsWait:
        navigate(ROUTE_PATHS.RevolvingDocumentsWait, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureParametars:
        navigate(ROUTE_PATHS.PreConfirmUserInfo, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureParametarsWait:
        navigate(ROUTE_PATHS.WaitOtherOwnerConfirmSigning, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureOTP:
        navigate(ROUTE_PATHS.EurotrustPhoneOtpValidation, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureOTPWait:
        navigate(ROUTE_PATHS.WaitFirstOwnerFinishSigning, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureWait:
        navigate(ROUTE_PATHS.DocumentSigningLoading, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureWaitAlternative:
        navigate(ROUTE_PATHS.DocumentSigningLoading, { replace: true });
        break;
      case STEP_ID.RevolvingDocumentsSignitureDenied:
        navigate(ROUTE_PATHS.RevolvingDocumentsSignitureDenied, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingDocumentsSignitureFailure:
        navigate(ROUTE_PATHS.RevolvingDocumentsSignitureFailure, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingDocumentsSignitureFailureAlternative:
        navigate(ROUTE_PATHS.RevolvingDocumentsSignitureFailureAlternative, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOnlineValidationsWait:
        navigate(ROUTE_PATHS.OnlineValidationsLoading, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOnlineValidationsDropped:
        navigate(ROUTE_PATHS.RevolvingOnlineValidationsDropped, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOnlineValidationsDroppedPersonalIdentity:
        navigate(ROUTE_PATHS.RevolvingOnlineValidationsDroppedPersonalIdentity, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOnlineValidationsDroppedActualState:
        navigate(ROUTE_PATHS.RevolvingOnlineValidationsDroppedActualState, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingOnlineValidationsDroppedCircumstances:
        navigate(ROUTE_PATHS.RevolvingOnlineValidationsDroppedCircumstances, {
          replace: true,
        });
        break;
      case STEP_ID.RevolvingFinalPage:
        navigate(ROUTE_PATHS.RevolvingFinalPage, { replace: true });
        break;
      case STEP_ID.RevolvingFinalPageCalculator:
        navigate(ROUTE_PATHS.RevolvingFinalPageCalculator, { replace: true });
        break;
      default:
        break;
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated && currentStep > 0) {
      gotoPage(currentStep);
    }
  }, [currentStep, isAuthenticated, gotoPage]);

  return null;
};
export default NavigationComponent;
