import {
  generalTermsStatus,
  generalTermsUrl,
  getGeneralTerms,
} from "../../providers/document/documentSlice";
import useViewPage from "../../hooks/useViewPage";

const GeneralTermsViewCreditOverview = () => {
  return useViewPage(
    generalTermsStatus,
    generalTermsUrl,
    getGeneralTerms,
    "Общи условия към договори за кредити",
    "Общи условия към договори за кредити.pdf"
  );
};
export default GeneralTermsViewCreditOverview();
