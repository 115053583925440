import { theme } from "../../theme/theme";

const styles = {
	maxWidth: "1196px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	flex: "1 0 auto",
	minHeight: "calc(100vh - 47px)",
	position: "relative",
	padding: theme.spacing(2),
	width: "100%",
	[theme.breakpoints.down("md")]: {
		padding: "30px",
		minHeight: "calc(100vh - 47px)",
	},
	[theme.breakpoints.up("md")]: {
		minHeight: "calc(100vh - 384px)",
		padding: "0 30px",
		maxWidth: "1196px",
	},
	[theme.breakpoints.up("lg")]: {
		minHeight: "calc(100vh - 344px)",
		maxWidth: "1196px",
	},
};

export default styles;
