export function saveLoanParamsRequest(loanParamsRequest) {
  const saveLoanParamsRequestOptions = {
    method: "POST",
    body: JSON.stringify(loanParamsRequest),
  };

  const saveLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/parameters`;
  return fetch(saveLoanParamsUrl, saveLoanParamsRequestOptions);
}

export function saveEditLoanParamsRequest(editLoanParamsRequest) {
  const saveEditLoanParamsRequestOptions = {
    method: "POST",
    body: JSON.stringify(editLoanParamsRequest),
  };

  const saveEditLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/edit_parameters`;
  return fetch(saveEditLoanParamsUrl, saveEditLoanParamsRequestOptions);
}

export function saveTermLoanParamsRequest(termLoanParamsRequest) {
  const saveTermLoanParamsRequestOptions = {
    method: "POST",
    body: JSON.stringify(termLoanParamsRequest),
  };

  const saveTermLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/parameters-termloan`;
  return fetch(saveTermLoanParamsUrl, saveTermLoanParamsRequestOptions);
}

export function editTermLoanParamsRequest(termLoanParamsRequest) {
  const editTermLoanParamsRequestOptions = {
    method: "POST",
    body: JSON.stringify(termLoanParamsRequest),
  };

  const editTermLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/edit-parameters-termloan`;
  return fetch(editTermLoanParamsUrl, editTermLoanParamsRequestOptions);
}

export function saveCreditLineParamsRequest(creditLineParamsRequest) {
  const options = {
    method: "POST",
    body: JSON.stringify(creditLineParamsRequest),
  };

  const saveTermLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/parameters-creditline`;
  return fetch(saveTermLoanParamsUrl, options);
}

export function editCreditLineParamsRequest(creditLineParamsRequest) {
  const options = {
    method: "POST",
    body: JSON.stringify(creditLineParamsRequest),
  };

  const editTermLoanParamsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/edit-parameters-creditline`;
  return fetch(editTermLoanParamsUrl, options);
}
