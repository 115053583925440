import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OTP_STATUS, STATUS } from "../../constants/constants";
import { setWithSignatureOTPResend } from "../application/applicationSlice";
import { setStep, setStepRequiresRefresh } from "../step/stepSlice";
import {
  fetchDocuSignOtpSend,
  fetchDocuSignOtpResend,
  fetchDocuSignBegin,
  docSignRestart,
} from "./documentSigningApi";

export const sendDocumentOtp = createAsyncThunk(
  "documents/sign/otp/send",
  async (activationCode, { rejectWithValue, dispatch }) => {
    try {
      var postData = {
        activationCode: activationCode,
      };

      const response = await fetchDocuSignOtpSend(postData);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      dispatch(
        setWithSignatureOTPResend(result.data ? result.data.withResend : false)
      );
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const resendDocumentOtp = createAsyncThunk(
  "documents/sign/otp/resend",
  async (phone, { rejectWithValue, dispatch }) => {
    try {
      var postData = {
        phone: phone,
      };

      const response = await fetchDocuSignOtpResend(postData);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      dispatch(
        setWithSignatureOTPResend(result.data ? result.data.withResend : false)
      );
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const documentSignBegin = createAsyncThunk(
  "documents/sign",
  async ({ phone, email }, { rejectWithValue, dispatch }) => {
    try {
      var postData = {
        phone: phone,
        email: email,
      };

      const response = await fetchDocuSignBegin(postData);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const documentSignRestart = createAsyncThunk(
  "documents/signRestart",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await docSignRestart();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setWithSignatureOTPResend(true));
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

const initialState = {
  tokenID: "",
  activationCode: 0,
  error: null,
  documentSignOtpStatus: STATUS.IDLE,
  sentOtpStatus: OTP_STATUS.IDLE,
  documentSignOtpResendStatus: STATUS.IDLE,
  documentSignBeginStatus: STATUS.IDLE,
  documentSignRestartStatus: STATUS.IDLE,
  otpValid: true,
};

export const documentSignSlice = createSlice({
  name: "documentsign",
  initialState,
  reducers: {
    setOtpValid(state, action) {
      state.otpValid = action.payload;
    },
    setOtpStatusIdle(state) {
      state.sentOtpStatus = OTP_STATUS.IDLE;
    },
    setResendStatus(state, action) {
      state.documentSignOtpResendStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendDocumentOtp.pending, (state) => {
        state.documentSignOtpStatus = STATUS.LOADING;
      })
      .addCase(sendDocumentOtp.fulfilled, (state, action) => {
        state.documentSignOtpStatus = STATUS.SUCCEEDED;
        state.sentOtpStatus = action.payload.data.status;
      })
      .addCase(sendDocumentOtp.rejected, (state, action) => {
        state.documentSignOtpStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(resendDocumentOtp.pending, (state) => {
        state.documentSignOtpResendStatus = STATUS.LOADING;
      })
      .addCase(resendDocumentOtp.fulfilled, (state) => {
        state.documentSignOtpResendStatus = STATUS.SUCCEEDED;
      })
      .addCase(resendDocumentOtp.rejected, (state, action) => {
        state.documentSignOtpResendStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(documentSignBegin.pending, (state) => {
        state.documentSignBeginStatus = STATUS.LOADING;
      })
      .addCase(documentSignBegin.fulfilled, (state, action) => {
        state.documentSignBeginStatus = STATUS.SUCCEEDED;
      })
      .addCase(documentSignBegin.rejected, (state, action) => {
        state.documentSignBeginStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(documentSignRestart.pending, (state) => {
        state.documentSignRestartStatus = STATUS.LOADING;
      })
      .addCase(documentSignRestart.fulfilled, (state, action) => {
        state.documentSignRestartStatus = STATUS.SUCCEEDED;
      })
      .addCase(documentSignRestart.rejected, (state, action) => {
        state.documentSignRestartStatus = STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});

export const CheckSendDocumentOtpStatus = (state) =>
  state.documentsign.documentSignOtpStatus;

export const CheckDocumentOtpResendStatus = (state) =>
  state.documentsign.documentSignOtpResendStatus;

export const CheckDocumentSignBeginStatus = (state) =>
  state.documentsign.documentSignBeginStatus;

export const CheckOtpStatus = (state) => state.documentsign.sentOtpStatus;

/// Get all actions
export const { setOtpValid, setResendStatus, setOtpStatusIdle } =
  documentSignSlice.actions;

export default documentSignSlice.reducer;
