import React from "react";
import Container from "@mui/material/Container";
import styles from "./layout-styles";
import PageProgressBar from "../page-progress/page-progress";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";

const Layout = (props) => {
  const { children } = props;
  const theme = useTheme();
  const style = {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "1136px",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.others.white,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      borderRadius: "25px",
      marginTop: "-50px",
      marginBottom: "30px",
      width: "auto",
      justifyContent: "center",
    },
  };
  return (
    <Container
      sx={{
        ...styles,
      }}
    >
      <PageProgressBar />
      <Box sx={{ ...style }}>{children}</Box>
    </Container>
  );
};

export default Layout;
