import {
  generalTermsEvrotrustStatus,
  generalTermsEvrotrustUrl,
  getGeneralTermsEvrotrust,
} from "../../providers/document/documentSlice";
import useViewPage from "../../hooks/useViewPage";

const GeneralTermsEvrotrustView = () => {
  return useViewPage(
    generalTermsEvrotrustStatus,
    generalTermsEvrotrustUrl,
    getGeneralTermsEvrotrust,
    "Oбщи условия за удостоверителни услуги с Евротръст",
    "Oбщи условия за удостоверителни услуги с Евротръст.pdf",
    0
  );
};
export default GeneralTermsEvrotrustView();
