import React from "react";
import { Button } from "@mui/material";
import ArrowBack from "../svgicons/ArrowBack";
import { useTheme } from "@emotion/react";

const BackButtonMobile = ({ onClickBack }) => {
	const theme = useTheme();
	const style = {
		position: "absolute",
		left: "0px",
		marginTop: "-77px",
		padding: 0,
		minWidth: "24px",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	};
	return (
		<Button onClick={onClickBack} sx={{ ...style }}>
			<ArrowBack />
		</Button>
	);
};

export default BackButtonMobile;
