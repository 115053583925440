export function fetchCheckUserIdentity(euroTrustIdentityBegin) {
  const checkUserIdentityRequestOptions = {
    method: "POST",
    body: JSON.stringify(euroTrustIdentityBegin),
  };

  const checkUserIdentityUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/DataPump/EvrotrustUserIdentityBegin`;
  return fetch(checkUserIdentityUrl, checkUserIdentityRequestOptions);
}

export function fetchEvrotrustUserIdentityEnd(euroTrustIdentityEnd) {
  const euroTrustIdentityRequestOptions = {
    method: "POST",
    body: JSON.stringify(euroTrustIdentityEnd),
  };

  const euroTrustIdentityUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/DataPump/EvrotrustUserIdentityEnd`;
  return fetch(euroTrustIdentityUrl, euroTrustIdentityRequestOptions);
}
