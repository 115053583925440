import React from "react";
import Lottie from "react-lottie-player";
import animationData from "../../assets/lottie-files/multi-spinner.json";

const CircularMultiSpinner = () => {
	return (
		<Lottie
			loop
			play
			animationData={animationData}
			style={{ maxHeight: 300, maxWidth: 300, margin: "0 auto" }}
		/>
	);
};

export default CircularMultiSpinner;
