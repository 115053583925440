import { Box, Typography } from "@mui/material";
import StyledContentWrapper from "../../styledComponents/ContentWrapper";
import LoginWaitIcon from "../svgicons/LoginWaitIcon";
import React from "react";

const LoginWaitScreen = ({ title, description }) => {
  return (
    <StyledContentWrapper>
      <Box className="loading-page">
        <LoginWaitIcon />
        <Typography component="div">
          <Typography gutterBottom variant="h4" component="div" sx={{ m: 3 }}>
            Моля, изчакайте!
          </Typography>
        </Typography>
        <Typography variant="h6" component="div">
          Вашата оферта се зарежда.
        </Typography>
      </Box>
    </StyledContentWrapper>
  );
};

export default LoginWaitScreen;
