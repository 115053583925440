import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SmallPhoneIconImg } from "../../assets/svgicons/OTP-icon-small-white-telephone.svg";

const SmallWhitePhoneIcon = (props) => {
  return (
    <SvgIcon component={SmallPhoneIconImg} {...props} viewBox="0 0 16 16" />
  );
};

export default SmallWhitePhoneIcon;
