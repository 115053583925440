import { Navigate } from "react-router-dom";
import { ROUTE_PATHS, STATUS } from "../../constants/constants";
import useApplicationData from "../../hooks/useApplicationData";
import GeneralError from "../general-error/general-error";
import LoadingScreen from "../loading/LoadingScreen";
import React from "react";

const PrivateRoute = ({
  component: Component,
  isUniqueIdPresent,
  loadingStatus,
}) => {
  const { generalErrorShown, firstLoad } = useApplicationData();

  return loadingStatus === STATUS.SUCCEEDED || firstLoad === false ? (
    isUniqueIdPresent ? (
      generalErrorShown ? (
        <GeneralError />
      ) : (
        <Component />
      )
    ) : (
      <Navigate replace to={ROUTE_PATHS.AuthenticationError} />
    )
  ) : loadingStatus === STATUS.FAILED ? (
    <Navigate replace to={ROUTE_PATHS.AuthenticationError} /> //stay on the page and show authentication error when session expired
  ) : (
    <LoadingScreen />
  );
};

export default PrivateRoute;
