// info centar number
export const CALL_CENTAR_PHONE = process.env.REACT_APP_CALL_CENTAR_PHONE;
export const CALL_CENTAR_PHONE_NUM =
  process.env.REACT_APP_CALL_CENTAR_PHONE_NUMBER;
//otp expiration time in seconds
export const OTP_EXPIRE_TIME = 180;
//max length of caracters in input
export const MAX_LENGTH_INPUT = 80;
//statuses of the async methods (api requests)
export const STATUS = {
  SUCCEEDED: "succeeded",
  IDLE: "idle",
  LOADING: "loading",
  FAILED: "failed",
};
export const OTP_STATUS = {
  IDLE: 0,
  SUCCEES: 1,
  FAILURE: 2,
  INVALID_CODE: 3,
};
export const ONLINE_VALIDATION_CHECK_INTERVAL = parseInt(
  process.env.REACT_APP_ONLINE_VALIDATION_CHECK_INTERVAL
);
export const CCR_VALIDATION_CHECK_INTERVAL = parseInt(
  process.env.REACT_APP_CCR_VALIDATION_CHECK_INTERVAL
);
export const DOCUMENT_SIGNING_CHECK_INTERVAL = parseInt(
  process.env.REACT_APP_DOCUMENT_SIGNING_CHECK_INTERVAL
);

export const WAITING_INTERVAL = parseInt(
  process.env.REACT_APP_WAITING_INTERVAL
);

export const phoneLink = `tel:${CALL_CENTAR_PHONE_NUM}`;

export const DOCUMENT_TYPES = {
  LOAN_APPLICATION: 1,
  LOAN_CONTRACT: 2,
  DECLARATIONS: 3,
  GENERAL_TERMS: 4,
  GENERAL_TERMS_EVROTRUST: 5,
  OVERDRAFT_ANEX: 6,
};

export const ROUTE_PATHS = {
  LandingPage: "/",
  TermLoanSuccessPreviewPartner: "/TermLoanSuccessPreviewPartner",
  OverdraftInfo: "/OverdraftInfo",
  TermLoanInfo: "/TermLoanInfo",
  CreditLineInfo: "/CreditLineInfo",
  Calculator: "/Calculator",
  CalculatorTermLoan: "/CalculatorTermLoan",
  CalculatorCreditLine: "/CalculatorCreditLine",
  CreditLineEditLoanParameters: "/CreditLineEditLoanParameters",
  StandardEditLoanParameters: "/EditLoanParameters",
  MonthlyCalculator: "/MonthlyCalculator",
  MonthlyCalculatorTermLoan: "/MonthlyCalculatorTermLoan",
  AcceptAgreement: "/AcceptAgreement",
  AcceptAgreementCodebtor: "/AcceptAgreementCodebtor",
  AcceptAgreementApproved: "/AcceptAgreementApproved",
  CreditNotApproved: "/CreditNotApproved",
  OnlineValidationsDroppedHold: "/OnlineValidationsDroppedBA",
  ExistingOverdraftDropped: "/OnlineValidationsDroppedOD",
  OverdarftOnlineValidationsDroppedCircumstances: "/OverdraftOnlineValidationsDroppedC",
  CodebtorCreditNotApproved: "/CodebtorCreditNotApproved",
  FinaliseOfferDocuments: "/FinaliseOfferDocuments",
  SendDeclarationsEmail: "/SendDeclarationsEmail",
  SendLoanApplicationEmail: "/SendLoanApplicationEmail",
  SendLoanContractEmail: "/SendLoanContractEmail",
  SendTermLoanContractEmail: "/SendLoanContractEmail",
  SendCreditLineContractEmail: "/SendLoanContractEmail",
  SendOverdraftAnexEmail: "/SendOverdraftAnexEmail",
  FinaliseOfferDocumentsDeclined: "/FinaliseOfferDocumentsDeclined",
  OnlineValidationsLoading: "/OnlineValidationsLoading",
  FinaliseOfferSuccess: "/FinaliseOfferSuccess",
  FinaliseOfferDeclined: "/FinaliseOfferDeclined",
  FinaliseOfferDeclinedSecondOwner: "/FinaliseOfferDeclinedSecondOwner",
  UnsuccessfulDocumentSigning: "/UnsuccessfulDocumentSigning",
  LoanContractView: "/LoanContractView",
  TermLoanContractView: "/TermLoanContractView",
  CreditLineContractView: "/CreditLineContractView",
  GeneralTermsView: "/GeneralTermsView",
  GeneralTermsViewCreditOverview: "/GeneralTermsViewCreditOverview",
  GeneralTermsEvrotrustView: "/GeneralTermsEvrotrustView",
  LoanApplicationView: "/LoanApplicationView",
  DeclarationView: "/DeclarationView",
  OverdraftAnexView: "/OverdraftAnexView",
  CreditOverview: "/CreditOverview",
  TermLoanCreditOverview: "/TermLoanCreditOverview",
  CreditLineLoanPreview: "/CreditLineLoanPreview",
  CreditLineLoanPreviewCodebtor: "/CreditLineLoanPreviewCodebtor",
  TermLoanEditLoanParameters: "/TermLoanEditLoanParameters",
  PreConfirmUserInfo: "/PreConfirmUserInfo",
  EurotrustPhoneOtpValidation: "/EurotrustPhoneOtpValidation",
  DocumentSigningLoading: "/DocumentSigningLoading",
  CCRValidationsLoading: "/CCRValidationsLoading",
  CodebtorCCRValidationsLoading: "/CodebtorValidationsLoading",
  JourneyExpireView: "/JourneyExpireView",
  AddSecondOwner: "/AddSecondOwner",
  WaitSecondOwner: "/WaitSecondOwner",
  ProvidePhoneEmail: "/ProvidePhoneEmail",
  WaitOtherOwnerConfirmSigning: "/WaitOtherOwnerConfirmSigning",
  WaitFirstOwnerFinishSigning: "/WaitFirstOwnerFinishSigning",
  SecondOwnerMandatory: "/SecondOwnerMandatory",
  NOT_FOUND: "*",
  AuthenticationError: "/AuthenticationError",
  RevolvingOfferAcceptedLoanParametars: "/RevolvingOfferAcceptedLoanParametars",
  RevolvingOfferAcceptedAlternativeCodebtor:
    "/RevolvingOfferAcceptedAlternativeCodebtor",
  RevolvingOfferDeclined: "/RevolvingOfferDeclined",
  RevolvingOfferDeclinedAlternativeCodebtor:
    "/RevolvingOfferDeclinedAlternativeCodebtor",
  RevolvingDocuments: "/RevolvingDocuments",
  RevolvingDocumentsWait: "/RevolvingDocumentsWait",
  RevolvingDocumentsParameters: "/RevolvingDocumentsParameters",
  RevolvingOnlineValidationsDropped: "/RevolvingOnlineValidationsDropped",
  RevolvingOnlineValidationsDroppedPersonalIdentity: "/RevolvingOnlineValidationsDroppedPI",
  RevolvingOnlineValidationsDroppedActualState: "/RevolvingOnlineValidationsDroppedAS",
  RevolvingOnlineValidationsDroppedCircumstances: "/RevolvingOnlineValidationsDroppedC",
  RevolvingDocumentsSignitureDenied: "/RevolvingDocumentsSignitureDenied",
  RevolvingDocumentsSignitureFailure: "/RevolvingDocumentsSignitureFailure",
  RevolvingDocumentsSignitureFailureAlternative:
    "/RevolvingDocumentsSignitureFailureAlternative",
  RevolvingFinalPage: "/RevolvingFinalPage",
  RevolvingFinalPageCalculator: "/RevolvingFinalPageCalculator"
};

export const PRODUCT_ID = {
  TermLoan: "54",
  Overdraft: "87",
  CreditLine: "53"
};