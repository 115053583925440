import React from "react";
import { Box } from "@mui/material";

const MainImageWrapper = ({ ImageComponent, ...imageProps }) => {
  return (
    <Box className="main-image-wrapper">
      <ImageComponent {...imageProps} />
    </Box>
  );
};

export default React.memo(MainImageWrapper);
