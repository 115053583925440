export function fetchGetDocumentsFileInfo() {
  const documentsFileInfoOptions = {
    method: "GET",
  };

  const documentsFileInfoUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents`;
  return fetch(documentsFileInfoUrl, documentsFileInfoOptions);
}

export function postDocumentsParametars(obj) {
  const documentsParametarsOptions = {
    method: "POST",
    body: JSON.stringify(obj),
  };

  const documentsParametarsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/parameters`;
  return fetch(documentsParametarsUrl, documentsParametarsOptions);
}

export function fetchGetLoanApplication() {
  const loanApplicationOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const loanApplicationUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_request`;
  return fetch(loanApplicationUrl, loanApplicationOptions);
}

export function fetchGetDeclarations() {
  const declarationOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const declarationUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_request_addendum`;
  return fetch(declarationUrl, declarationOptions);
}

export function fetchGetLoanContract() {
  const loanContractOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_contract`;
  return fetch(loanContractUrl, loanContractOptions);
}

export function fetchGetTermLoanContract() {
  const loanContractOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/termloan_contract`;
  return fetch(loanContractUrl, loanContractOptions);
}

export function fetchGetCreditLineContract() {
  const loanContractOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/creditline_contract`;
  return fetch(loanContractUrl, loanContractOptions);
}


export function fetchGeneralTerms() {
  const generalTermsOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const generalTermsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/general_terms`;
  return fetch(generalTermsUrl, generalTermsOptions);
}

export function fetchGeneralTermsEvrotrust() {
  const generalTermsEvrotrustOptions = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const generalTermsEvrotrustUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/general_terms_evrotrust`;
  return fetch(generalTermsEvrotrustUrl, generalTermsEvrotrustOptions);
}

export function fetchOverdraftAnex() {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*",
    },
  };

  const url = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_annex`;
  return fetch(url, options);
}

export function sendEmailLoanApplication(email) {
  const loanApplicationOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const loanApplicationUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_request/send_email`;
  return fetch(loanApplicationUrl, loanApplicationOptions);
}

export function sendEmailDeclarations(email) {
  const declarationOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const declarationUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_request_addendum/send_email`;
  return fetch(declarationUrl, declarationOptions);
}

export function sendEmailLoanContract(email) {
  const loanContractOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_contract/send_email`;
  return fetch(loanContractUrl, loanContractOptions);
}
export function sendEmailTermLoanContract(email) {
  const loanContractOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/termloan_contract/send_email`;
  return fetch(loanContractUrl, loanContractOptions);
}
export function sendEmailCreditLineContract(email) {
  const loanContractOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/creditline_contract/send_email`;
  return fetch(loanContractUrl, loanContractOptions);
}

export function sendEmailOverdraftAnnex(email) {
  const loanContractOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
  };

  const loanContractUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/overdraft_annex/send_email`;
  return fetch(loanContractUrl, loanContractOptions);
}
