import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/constants";

import {
  backwardLoanStepRequest,
  forwardLoanStepRequest,
  getLoanStepRequest,
} from "./stepApi";

export const forwardLoanStep = createAsyncThunk(
  "application/forwardLoanStep",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await forwardLoanStepRequest(getState().step.step);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var result = await response.json();
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const backwardLoanStep = createAsyncThunk(
  "application/backwardLoanStep",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await backwardLoanStepRequest(getState().step.step);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var result = await response.json();
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getLoanStep = createAsyncThunk(
  "application/getLoanStep",
  async (step, { rejectWithValue }) => {
    try {
      const response = await getLoanStepRequest();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var result = await response.json();
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

/// Default initial state object
const initialState = {
  step: 0,
  stepStatus: STATUS.IDLE,
  error: null,
  waitingMode: false,
  waitModeNumFails: 0,
  journeyStepRequiresRefresh: false,
};

/// Calculator slice
export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setStepRequiresRefresh(state, action) {
      state.journeyStepRequiresRefresh = action.payload;
    },
    setWaitingMode(state, action) {
      state.waitingMode = action.payload;
    },
    setWaitModeNumFails(state, action) {
      state.waitModeNumFails = action.payload;
    },
    setStepStatus(state, action) {
      state.stepStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forwardLoanStep.rejected, (state, action) => {
        state.stepStatus = STATUS.FAILED;
      })
      .addCase(forwardLoanStep.pending, (state, action) => {
        state.stepStatus = STATUS.LOADING;
      })
      .addCase(forwardLoanStep.fulfilled, (state, action) => {
        state.stepStatus = STATUS.SUCCEEDED;
        const { journeyStepId, journeyStepRequiresRefresh } = action.payload;
        state.step = journeyStepId;
        state.journeyStepRequiresRefresh = journeyStepRequiresRefresh;
      })
      .addCase(backwardLoanStep.rejected, (state, action) => {
        state.stepStatus = STATUS.FAILED;
      })
      .addCase(backwardLoanStep.pending, (state, action) => {
        state.stepStatus = STATUS.LOADING;
      })
      .addCase(backwardLoanStep.fulfilled, (state, action) => {
        state.stepStatus = STATUS.SUCCEEDED;
        const { journeyStepId, journeyStepRequiresRefresh } = action.payload;
        state.step = journeyStepId;
        state.journeyStepRequiresRefresh = journeyStepRequiresRefresh;
      })
      .addCase(getLoanStep.rejected, (state, action) => {
        if (!(state.waitingMode && state.waitModeNumFails < 2)) {
          state.stepStatus = STATUS.FAILED;
        }
      })
      .addCase(getLoanStep.pending, (state, action) => {
        state.stepStatus = STATUS.LOADING;
      })
      .addCase(getLoanStep.fulfilled, (state, action) => {
        state.stepStatus = STATUS.SUCCEEDED;
        const { journeyStepId, journeyStepRequiresRefresh } = action.payload;
        state.step = journeyStepId;
        state.journeyStepRequiresRefresh = journeyStepRequiresRefresh;
      });
  },
});

/// Get all actions
export const {
  setStep,
  setWaitingMode,
  setWaitModeNumFails,
  setStepRequiresRefresh,
  setStepStatus,
} = stepSlice.actions;

export default stepSlice.reducer;
