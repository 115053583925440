import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/constants";

import {
  fetchCheckUserIdentity,
  fetchEvrotrustUserIdentityEnd,
} from "../eurotrust-identification/euroTrustIdentificationApi";
import { setStep, setStepRequiresRefresh } from "../step/stepSlice";

export const checkUserIdentity = createAsyncThunk(
  "eurotrustIdentification/fetchCheckUserIdentity",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      var postData = {};

      const response = await fetchCheckUserIdentity(postData);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const checkEvrotrustUserIdentityEnd = createAsyncThunk(
  "eurotrustIdentification/fetchEvrotrustUserIdentityEnd",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      var postData = {};

      const response = await fetchEvrotrustUserIdentityEnd(postData);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  tokenID: "",
  isIdentified: false,
  error: null,
  euroTrustIdentitifactionStatus: STATUS.IDLE,
  euroTrustIdentityResultStatus: STATUS.IDLE,
};

export const euroTrustIdentificationSlice = createSlice({
  name: "eurotrustIdentification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkUserIdentity.pending, (state) => {
        state.euroTrustIdentitifactionStatus = STATUS.LOADING;
      })
      .addCase(checkUserIdentity.fulfilled, (state) => {
        state.euroTrustIdentitifactionStatus = STATUS.SUCCEEDED;
      })
      .addCase(checkUserIdentity.rejected, (state) => {
        state.euroTrustIdentitifactionStatus = STATUS.FAILED;
      })
      .addCase(checkEvrotrustUserIdentityEnd.pending, (state) => {
        state.euroTrustIdentityResultStatus = STATUS.LOADING;
      })
      .addCase(checkEvrotrustUserIdentityEnd.fulfilled, (state, action) => {
        state.isIdentified = action.payload.data.isIdentified;

        if (state.isIdentified) {
          state.euroTrustIdentityResultStatus = STATUS.SUCCEEDED;
        } else {
          state.euroTrustIdentityResultStatus = STATUS.IDLE;
        }
      })
      .addCase(checkEvrotrustUserIdentityEnd.rejected, (state, action) => {
        state.euroTrustIdentityResultStatus = STATUS.FAILED;
      });
  },
});

export const CheckUserIdentityStatus = (state) =>
  state.eurotrustIdentification.euroTrustIdentitifactionStatus;

export const CheckUserIdentityFinish = (state) =>
  state.eurotrustIdentification.euroTrustIdentityResultStatus;

export const IsEvroTrustIdentified = (state) =>
  state.eurotrustIdentification.isIdentified;

export default euroTrustIdentificationSlice.reducer;
