import { lazy, Suspense, React, useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import Layout from "./components/layout/layout.component";
import { Route } from "react-router-dom";
import PrivateRoute from "./components/routes/PrivateRoute";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { useDispatch } from "react-redux";
import {
  getLoan,
  setAuthenticated,
} from "./providers/application/applicationSlice";
import "./App.css";
import useApplicationData from "./hooks/useApplicationData";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import StyledMainBackground from "./styledComponents/MainBackground";
import { AuthInterceptor } from "./auth/interceptor";
import NavigationComponent from "./components/NavigationComponent";
import { ROUTE_PATHS, STATUS } from "./constants/constants";
import LoadingScreen from "./components/loading/LoadingScreen";
import LoginWaitScreen from "./components/loading/LoginWaitScreen";
import GeneralTermsView from "./features/finalise-offer/general-terms-view";
import GeneralTermsViewCreditOverview from "./features/credit-overview/general-terms-view-credit-overview";
import GeneralTermsEvrotrustView from "./features/finalise-offer/general-terms-evrotrust-view";
import WaitingSecondOwnerScreen from "./features/waiting-secondowner-screen/waiting-secondowner-screen";
import { Routes } from "react-router-dom";

const ProvidePhoneEmail = lazy(() =>
  import("./features/provide-phone-email/provide-phone-email")
);
const CreditOverview = lazy(() =>
  import("./features/credit-overview/credit-overview")
);
const TermLoanCreditOverview = lazy(() =>
  import("./features/credit-overview/termloan-credit-overview")
);
const CreditLineLoanPreview = lazy(() =>
  import("./features/credit-overview/CreditLine")
);
const EurotrustPhoneOtpValidation = lazy(() =>
  import("./features/eurotrust/eurotrust-phone-otp-validation")
);
const PreConfirmUserInfo = lazy(() =>
  import("./features/confirm-user-info/pre-confirm-user-info")
);
const MonthlyCalculator = lazy(() =>
  import("./features/monthly-calculator/monhtly-calculator")
);
const MonthlyCalculatorTermLoan = lazy(() =>
  import("./features/monthly-calculator/monthly-calculator-termloan")
);
const HomePage = lazy(() => import("./features/home-page/home-page"));
const NotFound = lazy(() => import("./features/not-found/not-found"));
const Calculator = lazy(() => import("./features/calculator/Calculator"));
const EditLoanParameters = lazy(() => import("./features/edit-loan-parameters/edit-loan-parameters"));
const CalculatorTermLoan = lazy(() =>
  import("./features/calculator/calculator-termloan")
);
const CalculatorCreditLine = lazy(() =>
  import("./features/calculator/CreditLine")
);
const TermLoanEditLoanParameters = lazy(() => import("./features/edit-loan-parameters/termloan-edit-parameters"));
const LandingPage = lazy(() => import("./features/landing-page/LandingPage"));
const OverdraftInfo = lazy(() =>
  import("./features/product-type/overdraft-info")
);
const TermLoanInfo = lazy(() =>
  import("./features/product-type/termloan-info")
);
const CreditLineInfo = lazy(() =>
  import("./features/product-type/creditline-info")
);
const AcceptAgreement = lazy(() =>
  import("./features/accept-agreement/accept-agreement")
);
const AcceptAgreementCodebtor = lazy(() =>
  import("./features/accept-agreement/accept-agreement-codebtor")
);
const AcceptAgreementApproved = lazy(() =>
  import("./features/accept-agreement/accept-agreement-approved")
);
const AcceptAgreementDeclined = lazy(() =>
  import("./features/accept-agreement/accept-agreement-declined")
);
const OverdraftOnlineValidationsDroppedHold = lazy(() =>
  import("./features/accept-agreement/overdraft-online-validations-dropped-hold")
);
const OverdarftOnlineValidationsDroppedCircumstances = lazy(() =>
  import("./features/accept-agreement/ovedraft-online-validations-dropped-circumstances")
);
const OnlineValidationsDroppedExistingOverdraft = lazy(() =>
  import("./features/accept-agreement/online-validations-dropped-existing-overdraft")
);
const FinaliseOfferDocuments = lazy(() =>
  import("./features/finalise-offer/finalise-offer-documents")
);
const SendDeclarationsEmail = lazy(() =>
  import("./features/send-document-email/send-declarations-email")
);
const SendLoanApplicationEmail = lazy(() =>
  import("./features/send-document-email/send-loan-application-email")
);
const SendLoanContractEmail = lazy(() =>
  import("./features/send-document-email/send-loan-contract-email")
);
const SendOverdraftAnexEmail = lazy(() =>
  import("./features/send-document-email/send-overdraft-anex-email")
);
const FinaliseOfferDocumentsDeclined = lazy(() =>
  import("./features/finalise-offer/finalise-offer-documents-declined")
);
const FinaliseOfferSuccess = lazy(() =>
  import("./features/finalise-offer/finalise-offer-success")
);
const FinaliseOfferDeclined = lazy(() =>
  import("./features/finalise-offer/finalise-offer-declined")
);
const FinaliseOfferDeclinedSecondOwner = lazy(() =>
  import("./features/finalise-offer/finalise-offer-declined-secondowner")
);
const UnsuccessfulDocumentSigning = lazy(() =>
  import("./features/document-signing/unsuccessful-document-signing")
);
const LoanContractView = lazy(() =>
  import("./features/finalise-offer/loan-contract-view")
);
const LoanApplicationView = lazy(() =>
  import("./features/finalise-offer/loan-application-view")
);
const DeclarationView = lazy(() =>
  import("./features/finalise-offer/declartion-view")
);
const OverdraftAnexView = lazy(() =>
  import("./features/finalise-offer/overdraft-anex-view")
);
const DocumentSigningLoading = lazy(() =>
  import("./features/document-signing/document-signing-loading")
);

const CCRValidationsLoading = lazy(() =>
  import("./features/ccrValidations-loading/ccrValidationsLoading")
);
const OnlineValidationsLoading = lazy(() =>
  import("./features/onlineValidations-loading/onlineValidationsLoading")
);

const JourneyExpire = lazy(() =>
  import("./features/journey-expire/journey-expire")
);

const WaitOtherOwnerConfirmSigning = lazy(() =>
  import(
    "./features/wait-other-owner-confirm-signing/wait-other-owner-confirm-signing"
  )
);
const WaitFirstOwnerFinishSigning = lazy(() =>
  import(
    "./features/wait-first-owner-finish-signing/wait-first-owner-finish-signing"
  )
);
const SecondOwnerMandatory = lazy(() =>
  import("./features/add-second-owner/second-owner-mandatory")
);
const AddSecondOwner = lazy(() =>
  import("./features/add-second-owner/add-second-owner")
);
const RevolvingOfferAcceptedLoanParametars = lazy(() =>
  import(
    "./features/revolving-journey/revolving-offer-accepted-loan-preview/revolving-offer-accepted-loan-parametars"
  )
);
const RevolvingOfferAcceptedAlternativeCodebtor = lazy(() =>
  import(
    "./features/revolving-journey/revolving-offer-accepted-loan-preview/revolving-offer-accepted-alternative-codebtor"
  )
);

const RevolvingOfferDeclined = lazy(() =>
  import(
    "./features/revolving-journey/revolving-offer-declined/revolving-offer-declined"
  )
);
const RevolvingOfferDeclinedAlternativeCodebtor = lazy(() =>
  import(
    "./features/revolving-journey/revolving-offer-declined/revolving-offer-declined-alternative-codebtor"
  )
);
const RevolvingFinalPage = lazy(() =>
  import(
    "./features/revolving-journey/revolving-final-page/revolving-final-page"
  )
);

const RevolvingOnlineValidationsDropped = lazy(() =>
  import(
    "./features/revolving-journey/revolving-online-validations-dropped/revolving-online-validations-dropped"
  )
);
const RevolvingOnlineValidationsDroppedPersonalIdentity = lazy(() =>
  import(
    "./features/revolving-journey/revolving-online-validations-dropped-pi/revolving-online-validations-dropped-pi"
  )
);
const RevolvingOnlineValidationsDroppedActualState = lazy(() =>
  import(
    "./features/revolving-journey/revolving-online-validations-dropped-as/revolving-online-validations-dropped-as"
  )
);
const RevolvingOnlineValidationsDroppedCircumstances = lazy(() =>
  import(
    "./features/revolving-journey/revolving-online-validations-dropped-circumstances/revolving-online-validations-dropped-circumstances"
  )
);
const RevolvingDocumentsSignitureDenied = lazy(() =>
  import(
    "./features/revolving-journey/error-pages/revolving-documents-signiture-denied"
  )
);
const RevolvingDocumentsSignitureFailure = lazy(() =>
  import(
    "./features/revolving-journey/error-pages/revolving-documents-signiture-failure"
  )
);
const RevolvingDocumentsSignitureFailureAlternative = lazy(() =>
  import(
    "./features/revolving-journey/error-pages/revolving-documents-signiture-failure-alternative"
  )
);
const RevolvingFinalPageCalculator = lazy(() =>
  import(
    "./features/revolving-journey/revolving-final-page-calculator/revolving-final-page-calculator"
  )
);

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, applicationStatus } = useApplicationData();
  const [uniqueId, setUniqueId] = useState(undefined);
  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(() => {
    if (query) {
      setUniqueId(query.get("uuid"));
    }
  }, [query, setUniqueId]);

  useEffect(() => {
    if (uniqueId) {
      const redirectLink = `${process.env.REACT_APP_INDETITY_CHALLENGE
        }?${query.toString()}`;
      window.location.replace(redirectLink);
    } else {
      dispatch(setAuthenticated(true));
    }
  }, [uniqueId, dispatch, query]);

  useEffect(() => {
    if (isAuthenticated && applicationStatus === STATUS.IDLE) {
      dispatch(getLoan());
    }
  }, [isAuthenticated, dispatch, applicationStatus]);

  return (
    <ThemeProvider theme={theme}>
      <AuthInterceptor />
      <StyledMainBackground>
        <Header />
        {uniqueId && (
          <Layout>
            <LoginWaitScreen />
          </Layout>
        )}
        {!uniqueId && (
          <Layout>
            <ScrollToTop />
            <NavigationComponent />
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                <Route
                  path={ROUTE_PATHS.AuthenticationError}
                  element={<HomePage />}
                />
                <Route
                  path={ROUTE_PATHS.LandingPage}
                  element={
                    <PrivateRoute
                      component={LandingPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.TermLoanSuccessPreviewPartner}
                  element={
                    <PrivateRoute
                      component={TermLoanCreditOverview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLoanPreview}
                  element={
                    <PrivateRoute
                      component={CreditLineLoanPreview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLoanPreviewCodebtor}
                  element={
                    <PrivateRoute
                      component={CreditLineLoanPreview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.OverdraftInfo}
                  element={
                    <PrivateRoute
                      component={OverdraftInfo}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.TermLoanInfo}
                  element={
                    <PrivateRoute
                      component={TermLoanInfo}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineInfo}
                  element={
                    <PrivateRoute
                      component={CreditLineInfo}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLandingPage}
                  element={
                    <PrivateRoute
                      component={LandingPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLandingPageCodebtor}
                  element={
                    <PrivateRoute
                      component={LandingPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLandingPageCodebtorMandatory}
                  element={
                    <PrivateRoute
                      component={LandingPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineLandingPagePartner}
                  element={
                    <PrivateRoute
                      component={LandingPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />

                <Route
                  path={ROUTE_PATHS.Calculator}
                  element={
                    <PrivateRoute
                      component={Calculator}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.StandardEditLoanParameters}
                  element={
                    <PrivateRoute
                      component={EditLoanParameters}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CalculatorTermLoan}
                  element={
                    <PrivateRoute
                      component={CalculatorTermLoan}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CalculatorCreditLine}
                  element={
                    <PrivateRoute
                      component={CalculatorCreditLine}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineEditLoanParameters}
                  element={
                    <PrivateRoute
                      component={CalculatorCreditLine}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.MonthlyCalculator}
                  element={
                    <PrivateRoute
                      component={MonthlyCalculator}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.MonthlyCalculatorTermLoan}
                  element={
                    <PrivateRoute
                      component={MonthlyCalculatorTermLoan}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.AcceptAgreement}
                  element={
                    <PrivateRoute
                      component={AcceptAgreement}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.AcceptAgreementApproved}
                  element={
                    <PrivateRoute
                      component={AcceptAgreementApproved}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditNotApproved}
                  element={
                    <PrivateRoute
                      component={AcceptAgreementDeclined}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.OnlineValidationsDroppedHold}
                  element={
                    <PrivateRoute
                      component={OverdraftOnlineValidationsDroppedHold}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.OverdarftOnlineValidationsDroppedCircumstances}
                  element={
                    <PrivateRoute
                      component={OverdarftOnlineValidationsDroppedCircumstances}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.ExistingOverdraftDropped}
                  element={
                    <PrivateRoute
                      component={OnlineValidationsDroppedExistingOverdraft}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.AcceptAgreementCodebtor}
                  element={
                    <PrivateRoute
                      component={AcceptAgreementCodebtor}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CodebtorCreditNotApproved}
                  element={
                    <PrivateRoute
                      component={AcceptAgreementDeclined}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.FinaliseOfferDocuments}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferDocuments}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.SendDeclarationsEmail}
                  element={
                    <PrivateRoute
                      component={SendDeclarationsEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.SendLoanApplicationEmail}
                  element={
                    <PrivateRoute
                      component={SendLoanApplicationEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.SendLoanContractEmail}
                  element={
                    <PrivateRoute
                      component={SendLoanContractEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.SendOverdraftAnexEmail}
                  element={
                    <PrivateRoute
                      component={SendOverdraftAnexEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.FinaliseOfferDocumentsDeclined}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferDocumentsDeclined}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.OnlineValidationsLoading}
                  element={
                    <PrivateRoute
                      component={OnlineValidationsLoading}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CodebtorCCRValidationsLoading}
                  element={
                    <PrivateRoute
                      component={OnlineValidationsLoading}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.FinaliseOfferSuccess}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferSuccess}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.FinaliseOfferDeclined}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferDeclined}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.FinaliseOfferDeclinedSecondOwner}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferDeclinedSecondOwner}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.UnsuccessfulDocumentSigning}
                  element={
                    <PrivateRoute
                      component={UnsuccessfulDocumentSigning}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.LoanContractView}
                  element={
                    <PrivateRoute
                      component={LoanContractView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditLineContractView}
                  element={
                    <PrivateRoute
                      component={LoanContractView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.TermLoanContractView}
                  element={
                    <PrivateRoute
                      component={LoanContractView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.GeneralTermsView}
                  element={
                    <PrivateRoute
                      component={GeneralTermsView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.GeneralTermsViewCreditOverview}
                  element={
                    <PrivateRoute
                      component={GeneralTermsViewCreditOverview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.GeneralTermsEvrotrustView}
                  element={
                    <PrivateRoute
                      component={GeneralTermsEvrotrustView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.LoanApplicationView}
                  element={
                    <PrivateRoute
                      component={LoanApplicationView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.DeclarationView}
                  element={
                    <PrivateRoute
                      component={DeclarationView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.OverdraftAnexView}
                  element={
                    <PrivateRoute
                      component={OverdraftAnexView}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CreditOverview}
                  element={
                    <PrivateRoute
                      component={CreditOverview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.TermLoanCreditOverview}
                  element={
                    <PrivateRoute
                      component={TermLoanCreditOverview}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.TermLoanEditLoanParameters}
                  element={
                    <PrivateRoute
                      component={TermLoanEditLoanParameters}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.PreConfirmUserInfo}
                  element={
                    <PrivateRoute
                      component={PreConfirmUserInfo}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.EurotrustPhoneOtpValidation}
                  element={
                    <PrivateRoute
                      component={EurotrustPhoneOtpValidation}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.DocumentSigningLoading}
                  element={
                    <PrivateRoute
                      component={DocumentSigningLoading}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CCRValidationsLoading}
                  element={
                    <PrivateRoute
                      component={CCRValidationsLoading}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.JourneyExpireView}
                  element={
                    <PrivateRoute
                      component={JourneyExpire}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={STATUS.SUCCEEDED}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.AddSecondOwner}
                  element={
                    <PrivateRoute
                      component={AddSecondOwner}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.WaitSecondOwner}
                  element={
                    <PrivateRoute
                      component={WaitingSecondOwnerScreen}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.ProvidePhoneEmail}
                  element={
                    <PrivateRoute
                      component={ProvidePhoneEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.WaitOtherOwnerConfirmSigning}
                  element={
                    <PrivateRoute
                      component={WaitOtherOwnerConfirmSigning}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.WaitFirstOwnerFinishSigning}
                  element={
                    <PrivateRoute
                      component={WaitFirstOwnerFinishSigning}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.SecondOwnerMandatory}
                  element={
                    <PrivateRoute
                      component={SecondOwnerMandatory}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOfferAcceptedLoanParametars}
                  element={
                    <PrivateRoute
                      component={RevolvingOfferAcceptedLoanParametars}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOfferDeclined}
                  element={
                    <PrivateRoute
                      component={RevolvingOfferDeclined}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOfferDeclinedAlternativeCodebtor}
                  element={
                    <PrivateRoute
                      component={RevolvingOfferDeclinedAlternativeCodebtor}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOfferAcceptedAlternativeCodebtor}
                  element={
                    <PrivateRoute
                      component={RevolvingOfferAcceptedAlternativeCodebtor}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingDocumentsParameters}
                  element={
                    <PrivateRoute
                      component={ProvidePhoneEmail}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingFinalPage}
                  element={
                    <PrivateRoute
                      component={RevolvingFinalPage}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingDocuments}
                  element={
                    <PrivateRoute
                      component={FinaliseOfferDocuments}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingDocumentsWait}
                  element={
                    <PrivateRoute
                      component={WaitingSecondOwnerScreen}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOnlineValidationsDropped}
                  element={
                    <PrivateRoute
                      component={RevolvingOnlineValidationsDropped}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOnlineValidationsDroppedPersonalIdentity}
                  element={
                    <PrivateRoute
                      component={RevolvingOnlineValidationsDroppedPersonalIdentity}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOnlineValidationsDroppedActualState}
                  element={
                    <PrivateRoute
                      component={RevolvingOnlineValidationsDroppedActualState}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingOnlineValidationsDroppedCircumstances}
                  element={
                    <PrivateRoute
                      component={RevolvingOnlineValidationsDroppedCircumstances}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingDocumentsSignitureDenied}
                  element={
                    <PrivateRoute
                      component={RevolvingDocumentsSignitureDenied}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.RevolvingDocumentsSignitureFailure}
                  element={
                    <PrivateRoute
                      component={RevolvingDocumentsSignitureFailure}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={
                    ROUTE_PATHS.RevolvingDocumentsSignitureFailureAlternative
                  }
                  element={
                    <PrivateRoute
                      component={RevolvingDocumentsSignitureFailureAlternative}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />
                <Route
                  path={
                    ROUTE_PATHS.RevolvingFinalPageCalculator
                  }
                  element={
                    <PrivateRoute
                      component={RevolvingFinalPageCalculator}
                      isUniqueIdPresent={isAuthenticated}
                      loadingStatus={applicationStatus}
                      exact
                    />
                  }
                />

                <Route
                  path={ROUTE_PATHS.NOT_FOUND}
                  element={<NotFound isUniqueIdPresent={isAuthenticated} />}
                />
              </Routes>
            </Suspense>
          </Layout>
        )}

        <Footer />
      </StyledMainBackground>
    </ThemeProvider>
  );
}

export default App;
