export function forwardLoanStepRequest(step) {
  const sendLoanStepForwardRequestOptions = {
    method: "POST",
    body: JSON.stringify({ journeyStepId: step }),
  };

  const forwardLoanStepUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/step/forward`;
  return fetch(forwardLoanStepUrl, sendLoanStepForwardRequestOptions);
}

export function backwardLoanStepRequest(step) {
  const sendLoanStepForwardRequestOptions = {
    method: "POST",
    body: JSON.stringify({ journeyStepId: step }),
  };

  const forwardLoanStepUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/step/backward`;
  return fetch(forwardLoanStepUrl, sendLoanStepForwardRequestOptions);
}

export function getLoanStepRequest() {
  const getLoanStepRequestOptions = {
    method: "GET",
  };

  const getLoanStepUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/step`;
  return fetch(getLoanStepUrl, getLoanStepRequestOptions);
}
