import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  saveLoanParamsRequest,
  saveEditLoanParamsRequest,
  saveTermLoanParamsRequest,
  editTermLoanParamsRequest,
  saveCreditLineParamsRequest,
  editCreditLineParamsRequest
} from "../../providers/calculator/calculatorApi";
import {
  calculateIncrementSlider,
  calculateDecrementSlider,
} from "../../helpers/calculator/slider";
import {
  calculateInterestPayment,
  calculateRegularPayments,
  calculateMaintanceFee,
  calculateFirstMonthPayment
} from "../../helpers/calculator/calculation";
import * as Constants from "../../helpers/calculator/constants";
import { getLoan } from "../application/applicationSlice";
import { STATUS } from "../../constants/constants";
import { setStep, setStepRequiresRefresh } from "../step/stepSlice";

export const saveLoanParams = createAsyncThunk(
  "calculator/saveLoanParams",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await saveLoanParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const saveEditLoanParams = createAsyncThunk(
  "calculator/saveEditLoanParams",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await saveEditLoanParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const saveTermLoanParams = createAsyncThunk(
  "calculator/saveTermLoanParams",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await saveTermLoanParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);
export const saveCreditLineLoanParams = createAsyncThunk(
  "loan/parameters-creditline",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await saveCreditLineParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const editCreditLineLoanParams = createAsyncThunk(
  "loan/edit-parameters-termloan",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await editCreditLineParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const editTermLoanParams = createAsyncThunk(
  "calculator/editTermLoanParams",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { loanParams } = getState().calculator;

      const response = await editTermLoanParamsRequest(loanParams);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

/// Default initial state object
const initialState = {
  isOverdraft: true,
  isCreditLine: true,
  loanParams: {
    currency: "BGN",
    productId: 0,
    montlyInterestPayment: 0,
    firstMonthPayment: 0,
    regularPayment: 0,
    maintanceFee: 0,
    managementFee: 0,
    commitmentFee: 0,
    interestRate: 4.5,
    sliderLoanValue: 0,
    sliderDurationValue: 0,
    sliderRepaymentValue: 0,
    sliderRepaymentStep: 0,
    sliderDurationStep: 0,
    sliderRepaymentMin: 0,
    sliderRepaymentMax: 0,
    sliderLoanStep: 0,
    sliderDurationMin: 0,
    sliderDurationMax: 0,
    sliderLoanMin: 0,
    sliderLoanMax: 0,
    errorCode: 0,
    errorDescription: "",
    withCalculator: false,
    sliderGraceValue: ""
  },
  calculatorStatus: STATUS.IDLE,
  loanParamsSaved: false,
  error: null,
};

/// Calculator slice
export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    updateSliderValue(state, action) {
      const { value, type } = action.payload;

      if (type === Constants.LoanAmount) {
        state.loanParams.sliderLoanValue = value;
      }

      if (type === Constants.LoanDuration) {
        state.loanParams.sliderDurationValue = value;
      }

      if (type === Constants.ExpiryRepayment) {
        state.loanParams.sliderRepaymentValue = value;
      }
    },
    incrementSliderValue(state, action) {
      const { type } = action.payload;

      if (type === Constants.LoanAmount) {
        state.loanParams.sliderLoanValue = calculateIncrementSlider(
          state.loanParams.sliderLoanValue,
          state.loanParams.sliderLoanStep,
          state.loanParams.sliderLoanMax
        );
      }

      if (type === Constants.LoanDuration) {
        state.loanParams.sliderDurationValue = calculateIncrementSlider(
          state.loanParams.sliderDurationValue,
          state.loanParams.sliderDurationStep,
          state.loanParams.sliderDurationMax
        );
      }

      if (type === Constants.ExpiryRepayment) {
        state.loanParams.sliderRepaymentValue = calculateIncrementSlider(
          state.loanParams.sliderRepaymentValue,
          state.loanParams.sliderRepaymentStep,
          state.loanParams.sliderRepaymentMax
        );
      }
    },
    decrementSliderValue(state, action) {
      const { type } = action.payload;

      if (type === Constants.LoanAmount) {
        state.loanParams.sliderLoanValue = calculateDecrementSlider(
          state.loanParams.sliderLoanValue,
          state.loanParams.sliderLoanStep,
          state.loanParams.sliderLoanMin,
          state.loanParams.sliderLoanMax
        );
      }

      if (type === Constants.LoanDuration) {
        state.loanParams.sliderDurationValue = calculateDecrementSlider(
          state.loanParams.sliderDurationValue,
          state.loanParams.sliderDurationStep,
          state.loanParams.sliderDurationMin,
          state.loanParams.sliderDurationMax
        );
      }

      if (type === Constants.ExpiryRepayment) {
        state.loanParams.sliderRepaymentValue = calculateDecrementSlider(
          state.loanParams.sliderRepaymentValue,
          state.loanParams.sliderRepaymentStep,
          state.loanParams.sliderRepaymentMin,
          state.loanParams.sliderRepaymentMax
        );
      }
    },
    chooseOverdraft(state, action) {
      const { isOverdraft } = action.payload;
      state.isOverdraft = isOverdraft;
      state.isCreditLine = !isOverdraft;
    },
    chooseCreditLine(state, action) {
      const { isCreditLine } = action.payload;
      state.isCreditLine = isCreditLine;
      state.isOverdraft = !isCreditLine;
    },
    calculateLoanData(state) {
      const {
        sliderLoanValue,
        interestRate,
        sliderDurationValue,
        sliderRepaymentValue,
      } = state.loanParams;

      state.loanParams.montlyInterestPayment = calculateInterestPayment(
        sliderLoanValue,
        interestRate,
        sliderDurationValue
      );

      state.loanParams.firstMonthPayment = calculateFirstMonthPayment(
        sliderLoanValue,
        interestRate,
        sliderDurationValue
      );

      state.loanParams.regularPayment = calculateRegularPayments(
        sliderLoanValue,
        sliderRepaymentValue,
        sliderLoanValue,
        interestRate
      );

      state.loanParams.maintanceFee = calculateMaintanceFee(sliderLoanValue);
    },
    setLoanParametarsSaved(state, action) {
      state.loanParamsSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoan.pending, (state, action) => {
        state.calculatorStatus = STATUS.LOADING;
      })
      .addCase(getLoan.fulfilled, (state, action) => {
        state.calculatorStatus = STATUS.SUCCEEDED;
        const applicationState = action.payload;
        state.loanParams = applicationState.data.parameters;
      })
      .addCase(getLoan.rejected, (state, action) => {
        state.calculatorStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(saveLoanParams.pending, (state, action) => {
        state.calculatorStatus = STATUS.LOADING;
      })
      .addCase(saveLoanParams.fulfilled, (state, action) => {
        state.calculatorStatus = "succeed";
        state.loanParamsSaved = true;
      })
      .addCase(saveLoanParams.rejected, (state, action) => {
        state.calculatorStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(saveEditLoanParams.pending, (state, action) => {
        state.calculatorStatus = STATUS.LOADING;
      })
      .addCase(saveEditLoanParams.fulfilled, (state, action) => {
        state.calculatorStatus = "succeed";
        state.loanParamsSaved = true;
      })
      .addCase(saveEditLoanParams.rejected, (state, action) => {
        state.calculatorStatus = STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});

/// Get all actions
export const {
  updateSliderValue,
  incrementSliderValue,
  decrementSliderValue,
  chooseOverdraft,
  chooseCreditLine,
  calculateLoanData,
  setLoanParametarsSaved,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
