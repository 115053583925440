import fetchIntercept from "fetch-intercept";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useHandleCodes from "../components/routes/useHandleCodes";
import useStepData from "../hooks/useStepData";
import {
  setAuthenticated,
  setShowGeneralError,
} from "../providers/application/applicationSlice";
import {
  setWaitingMode,
  setWaitModeNumFails,
} from "../providers/step/stepSlice";
export const AuthInterceptor = () => {
  const dispatch = useDispatch();
  const { setCode } = useHandleCodes();
  const { waitingMode, waitModeNumFails } = useStepData();
  const [errorResponse, setErrorResponse] = useState(null);
  const firstMount = useRef(true);
  const checkErrorResponse = useCallback(
    (response) => {
      const getBody = async (response) => {
        try {
          const body = await response.json();
          body.code ? setCode(body.code) : dispatch(setShowGeneralError(true));
        } catch (error) {
          dispatch(setShowGeneralError(true));
        }
      };
      if (response.status === 401) {
        dispatch(setAuthenticated(false));
      } else if (
        // handle get step request when waiting online validation, ccr validations or signing documents
        //if getStep fails more than 3 time show error
        response.url.indexOf("loan/step") > -1 &&
        response.request.method === "GET" &&
        waitingMode &&
        waitModeNumFails < 2
      ) {
        dispatch(setWaitModeNumFails(waitModeNumFails + 1));
      } else {
        if (waitingMode) {
          dispatch(setWaitingMode(false));
          dispatch(setWaitModeNumFails(0));
        }
        getBody(response);
      }
    },
    [dispatch, waitingMode, setCode, waitModeNumFails]
  );
  useEffect(() => {
    if (errorResponse) {
      checkErrorResponse(errorResponse);
    }
  }, [errorResponse, checkErrorResponse]);
  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      fetchIntercept.register({
        request: function (url, config) {
          // Modify the url or config here
          config.cache = "no-store";
          config.credentials = "include";
          if (!config.headers) {
            config.method === "POST"
              ? (config.headers = {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                })
              : (config.headers = {
                  Accept: "application/json",
                });
          } else {
            if (!config.headers["Content-Type"]) {
              config.headers["Content-Type"] = "application/json";
            }
          }
          const request = new Request(url, config);
          return [request];
        },

        requestError: function (error) {
          dispatch(setShowGeneralError(true));
          // Called when an error occured during another 'request' interceptor call
          return Promise.reject(error);
        },

        response: function (response) {
          // Modify the reponse object
          if (!response.ok) {
            setErrorResponse(response);
          }
          return response;
        },

        responseError: function (error) {
          dispatch(setShowGeneralError(true));
          // Handle an fetch error
          return Promise.reject(error);
        },
      });
    }
  }, [dispatch]);

  return null;
};
