import { Box, Typography } from "@mui/material";
import StyledContentWrapper from "../../styledComponents/ContentWrapper";
import CircularMultiSpinner from "../svgicons/CircularMultiSpinner";
import React from "react";

const LoadingDocumentBox = ({ title }) => {
  return (
    <StyledContentWrapper>
      <Box className="loading-page">
        <Typography sx={{ mt: 10 }} component="div">
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{ textAlign: "center", mb: 6, width: "100%" }}
          >
            {title}
          </Typography>
        </Typography>
        <CircularMultiSpinner />
        <Typography
          sx={{ mt: 6, textAlign: "center" }}
          gutterBottom
          variant="h6"
          component="div"
        >
          В момента подготвямe документа.
        </Typography>
        <Typography variant="h6" component="div">
          Моля, изчакайте!
        </Typography>
      </Box>
    </StyledContentWrapper>
  );
};

export default LoadingDocumentBox;
