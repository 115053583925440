import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as GeneralErrorSvg } from "../../assets/svgicons/GeneralErrorIcon.svg";

const GeneralErrorIcon = (props) => {
	return (
		<SvgIcon component={GeneralErrorSvg} {...props} viewBox="0 0 366 244" />
	);
};

export default GeneralErrorIcon;
