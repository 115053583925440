import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/constants";

import {
  fetchGetLoanApplication,
  fetchGetDeclarations,
  fetchGetLoanContract,
  fetchGetDocumentsFileInfo,
  fetchGeneralTerms,
  fetchGeneralTermsEvrotrust,
  postDocumentsParametars,
  sendEmailLoanApplication,
  sendEmailLoanContract,
  sendEmailDeclarations,
  sendEmailOverdraftAnnex,
  fetchOverdraftAnex,
  fetchGetTermLoanContract,
  sendEmailTermLoanContract,
  fetchGetCreditLineContract,
  sendEmailCreditLineContract
} from "../../providers/document/documentApi";
import { setStep, setStepRequiresRefresh } from "../step/stepSlice";

export const getDocumentsFileInfo = createAsyncThunk(
  "document",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchGetDocumentsFileInfo();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      const data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const saveDocumentsParametars = createAsyncThunk(
  "document/parametars",
  async (data, { rejectWithValue, dispatch }) => {
    const obj = {
      phone: data.phone,
      email: data.email,
    };
    try {
      const response = await postDocumentsParametars(obj);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      const data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getLoanApplication = createAsyncThunk(
  "document/overdraft_request",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGetLoanApplication();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getLoanContract = createAsyncThunk(
  "document/overdraft_contract",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGetLoanContract();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getTermLoanContract = createAsyncThunk(
  "document/termloan_contract",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGetTermLoanContract();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getCreditLineContract = createAsyncThunk(
  "document/creditline_contract",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGetCreditLineContract();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getDeclarations = createAsyncThunk(
  "document/overdraft_request_addendum",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGetDeclarations();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getGeneralTerms = createAsyncThunk(
  "document/general_terms",
  async (_, { rejectWithValue }) => {
    return await fetchGeneralTerms()
      .then(async (response) => {
        if (!response.ok) {
          rejectWithValue(response.data.message);
        }

        return await response.blob()
      })
      .then(data => URL.createObjectURL(data))
      .catch(error => rejectWithValue(error));
  }
);

export const getGeneralTermsEvrotrust = createAsyncThunk(
  "document/general_terms_evrotrust",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchGeneralTermsEvrotrust();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);
export const getOverdraftAnex = createAsyncThunk(
  "document/get_overdraft_anex",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await fetchOverdraftAnex();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);
export const sendLoanApplicationEmail = createAsyncThunk(
  "document/overdraft_request/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailLoanApplication(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);
export const sendCreditLineContractEmail = createAsyncThunk(
  "document/creditline_contract/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailCreditLineContract(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const sendLoanContractEmail = createAsyncThunk(
  "document/overdraft_contract/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailLoanContract(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const sendTermLoanContractEmail = createAsyncThunk(
  "documents/termloan_contract/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailTermLoanContract(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const sendDeclarationsEmail = createAsyncThunk(
  "document/overdraft_request_addendum/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailDeclarations(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const sendOverdraftAnnexEmail = createAsyncThunk(
  "documents/overdraft_annex/send_email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await sendEmailOverdraftAnnex(email);

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var blobData = await response.blob();
      var urlFromBlob = URL.createObjectURL(blobData);

      return urlFromBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

const initialState = {
  documentsFIleInfo: [],
  loanCotranctBlobFile: "",
  loanApplicationBlobFile: "",
  declarationsBlobFile: "",
  generalTermsBlobFile: "",
  generalTermsEvrotrustBlobFile: "",
  overdraftAnexBlobFile: "",
  loanContractStatus: STATUS.IDLE,
  declarationStatus: STATUS.IDLE,
  loanApplicationStatus: STATUS.IDLE,
  documentsFileInfoStatus: STATUS.IDLE,
  generalTermsStatus: STATUS.IDLE,
  generalTermsEvrotrustStatus: STATUS.IDLE,
  overdraftAnexStatus: STATUS.IDLE,
  loanApplicationError: null,
  declarationError: null,
  loanContractError: null,
  documentsFileInfoError: null,
  generalTermsError: null,
  generalTermsEvrotrustError: null,
  goverdraftAnexError: null,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    resetGeneralTermsStatus(state, action) {
      state.generalTermsStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsFileInfo.pending, (state) => {
        state.documentsFileInfoStatus = STATUS.LOADING;
      })
      .addCase(getDocumentsFileInfo.fulfilled, (state, action) => {
        state.documentsFileInfoStatus = STATUS.SUCCEEDED;
        state.documentsFIleInfo.push(action.payload.data);
      })
      .addCase(getDocumentsFileInfo.rejected, (state, action) => {
        state.documentsFileInfoStatus = STATUS.FAILED;
        state.documentsFileInfoError = action.error.message;
      })
      .addCase(getLoanApplication.pending, (state) => {
        state.loanApplicationStatus = STATUS.LOADING;
      })
      .addCase(getLoanApplication.fulfilled, (state, action) => {
        state.loanApplicationStatus = STATUS.SUCCEEDED;
        state.loanApplicationBlobFile = action.payload;
      })
      .addCase(getLoanApplication.rejected, (state, action) => {
        state.loanApplicationStatus = STATUS.FAILED;
        state.loanApplicationError = action.error.message;
      })
      .addCase(getLoanContract.pending, (state) => {
        state.loanContractStatus = STATUS.LOADING;
      })
      .addCase(getLoanContract.fulfilled, (state, action) => {
        state.loanContractStatus = STATUS.SUCCEEDED;
        state.loanCotranctBlobFile = action.payload;
      })
      .addCase(getLoanContract.rejected, (state, action) => {
        state.loanContractStatus = STATUS.FAILED;
        state.loanContractError = action.error.message;
      })
      .addCase(getTermLoanContract.pending, (state) => {
        state.loanContractStatus = STATUS.LOADING;
      })
      .addCase(getTermLoanContract.fulfilled, (state, action) => {
        state.loanContractStatus = STATUS.SUCCEEDED;
        state.loanCotranctBlobFile = action.payload;
      })
      .addCase(getTermLoanContract.rejected, (state, action) => {
        state.loanContractStatus = STATUS.FAILED;
        state.loanContractError = action.error.message;
      })
      .addCase(getCreditLineContract.pending, (state) => {
        state.loanContractStatus = STATUS.LOADING;
      })
      .addCase(getCreditLineContract.fulfilled, (state, action) => {
        state.loanContractStatus = STATUS.SUCCEEDED;
        state.loanCotranctBlobFile = action.payload;
      })
      .addCase(getCreditLineContract.rejected, (state, action) => {
        state.loanContractStatus = STATUS.FAILED;
        state.loanContractError = action.error.message;
      })
      .addCase(getDeclarations.pending, (state) => {
        state.declarationStatus = STATUS.LOADING;
      })
      .addCase(getDeclarations.fulfilled, (state, action) => {
        state.declarationStatus = STATUS.SUCCEEDED;
        state.declarationsBlobFile = action.payload;
      })
      .addCase(getDeclarations.rejected, (state, action) => {
        state.declarationStatus = STATUS.FAILED;
        state.declarationError = action.error.message;
      })
      .addCase(getGeneralTerms.pending, (state) => {
        state.generalTermsStatus = STATUS.LOADING;
      })
      .addCase(getGeneralTerms.fulfilled, (state, action) => {
        state.generalTermsStatus = STATUS.SUCCEEDED;
        state.generalTermsBlobFile = action.payload;
      })
      .addCase(getGeneralTerms.rejected, (state, action) => {
        state.generalTermsStatus = STATUS.FAILED;
        state.generalTermsError = action.error.message;
      })
      .addCase(getGeneralTermsEvrotrust.pending, (state) => {
        state.generalTermsEvrotrustStatus = STATUS.LOADING;
      })
      .addCase(getGeneralTermsEvrotrust.fulfilled, (state, action) => {
        state.generalTermsEvrotrustStatus = STATUS.SUCCEEDED;
        state.generalTermsEvrotrustBlobFile = action.payload;
      })
      .addCase(getGeneralTermsEvrotrust.rejected, (state, action) => {
        state.generalTermsEvrotrustStatus = STATUS.FAILED;
        state.generalTermsEvrotrustError = action.error.message;
      })
      .addCase(getOverdraftAnex.pending, (state) => {
        state.overdraftAnexStatus = STATUS.LOADING;
      })
      .addCase(getOverdraftAnex.fulfilled, (state, action) => {
        state.overdraftAnexStatus = STATUS.SUCCEEDED;
        state.overdraftAnexBlobFile = action.payload;
      })
      .addCase(getOverdraftAnex.rejected, (state, action) => {
        state.overdraftAnexStatus = STATUS.FAILED;
        state.goverdraftAnexError = action.error.message;
      });
  },
});

export const loanContractStatus = (state) => state.document.loanContractStatus;
export const loanApplicationStatus = (state) =>
  state.document.loanApplicationStatus;
export const declarationStatus = (state) => state.document.declarationStatus;
export const fileInfoStatus = (state) => state.document.documentsFileInfoStatus;
export const generalTermsStatus = (state) => state.document.generalTermsStatus;
export const { resetGeneralTermsStatus } = documentSlice.actions;
export const generalTermsEvrotrustStatus = (state) =>
  state.document.generalTermsEvrotrustStatus;
export const overdraftAnexStatus = (state) =>
  state.document.overdraftAnexStatus;

export const loanContractUrl = (state) => state.document.loanCotranctBlobFile;
export const loanApplicationUrl = (state) =>
  state.document.loanApplicationBlobFile;
export const declarationUrl = (state) => state.document.declarationsBlobFile;
export const generalTermsUrl = (state) => state.document.generalTermsBlobFile;
export const generalTermsEvrotrustUrl = (state) =>
  state.document.generalTermsEvrotrustBlobFile;
export const overdraftAnexUrl = (state) => state.document.overdraftAnexBlobFile;

export default documentSlice.reducer;
