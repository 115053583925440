import React from "react";
import TopBarLogoSvg from "../../assets/svgicons/OTP-icon-top-bar-logo-desktop.svg";

const TopBarLogoIconDesktop = () => {
	return (
		<img
			src={TopBarLogoSvg}
			width={190}
			style={{
				margin: "auto",
				top: "27px",
				left: "24px",
				marginBottom: "10px",
				position: "absolute",
			}}
			alt="TopBarLogo"
		/>
	);
};

export default TopBarLogoIconDesktop;
