import React from "react";
import CallCenterIconSvg from "../../assets/svgicons/OTP-icon-call-center.svg";
import { CALL_CENTAR_PHONE_NUM } from "../../constants/constants";

const link = `tel:${CALL_CENTAR_PHONE_NUM}`;

const CallCenterIcon = (props) => {
  return (
    <a href={link}>
      <img src={CallCenterIconSvg} alt="Call Center" className={props.cs} />
    </a>
  );
};

export default CallCenterIcon;
