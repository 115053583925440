import ViewPage from "../components/ViewPage";
import React from "react";

const useViewPage = (
  statusSelector,
  urlSelector,
  getAction,
  title,
  downloadFileName,
  viewNum
) => {
  const props = {
    statusSelector,
    urlSelector,
    getAction,
    title,
    downloadFileName,
    viewNum,
  };
  return () => <ViewPage {...props} />;
};

export default useViewPage;
