import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ArrowBackIcon } from "../../assets/svgicons/OTP-icon-24x24-arrow-left.svg";

const ArrowBack = (props) => {
	return (
		<SvgIcon
			component={ArrowBackIcon}
			sx={{ fill: "transparent" }}
			viewBox="0 0 16 16"
			fill="#ffffff"
			{...props}
		/>
	);
};

export default ArrowBack;
