import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "../providers/application/applicationSlice";
import calculatorReducer from "../providers/calculator/calculatorSlice";
import bankAccountsReducer from "../providers/bank-accounts/bankAccountsSlice";
import eurotrustIdentificationReducer from "../providers/eurotrust-identification/euroTrustIdentificationSlice";
import documentsignReducer from "../providers/eurotrust-documentsigning/documentSigningSlice";
import documentsReducer from "../providers/document/documentSlice";
import declarationsReducer from "../providers/declarations/declarationsSlice";
import stepReducer from "../providers/step/stepSlice";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

/// Combine reducer
const rootReducer = combineReducers({
  application: applicationReducer,
  calculator: calculatorReducer,
  bankAccounts: bankAccountsReducer,
  eurotrustIdentification: eurotrustIdentificationReducer,
  document: documentsReducer,
  documentsign: documentsignReducer,
  declarations: declarationsReducer,
  step: stepReducer,
});

// Persist configuration
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: [],
};

/// Persist reducer with configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

/// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

/// Export persist store
export const persistor = persistStore(store);
export default store;
