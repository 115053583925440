import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/constants";

import { setStep, setStepRequiresRefresh } from "../step/stepSlice";
import {
  fetchBankAccountsRequest,
  saveBankAccountsRequest,
} from "./bankAccountsApi";

export const getBankAccounts = createAsyncThunk(
  "loan-get/bank-accounts",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchBankAccountsRequest();

      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);
export const saveBankAccounts = createAsyncThunk(
  "loan-save/bank-account",
  async (account, { rejectWithValue, dispatch }) => {
    try {
      const response = await saveBankAccountsRequest({ accountIBAN: account });
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var data = await response.json();
      dispatch(setStep(data.journeyStepId));
      dispatch(setStepRequiresRefresh(data.journeyStepRequiresRefresh));
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

/// Default initial state object
const initialState = {
  accountIBANs: [],
  bankAccountStep: undefined,
  status: STATUS.IDLE,
  bankAccountSaved: false,
  error: null,
};

/// Calculator slice
export const bankAccountsSlice = createSlice({
  name: "bankAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBankAccounts.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        const accountIBANs = action.payload.data;
        state.accountIBANs = accountIBANs.accountIBANs;
        state.bankAccountStep = accountIBANs.step;
      })
      .addCase(getBankAccounts.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(saveBankAccounts.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(saveBankAccounts.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.bankAccountSaved = true;
      })
      .addCase(saveBankAccounts.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});

export default bankAccountsSlice.reducer;
