import React from "react";
import { Box, Button } from "@mui/material";

const OneButtonHolder = ({
  label,
  onClickContinue,
  type,
  holderStyles,
  buttonStyles,
  holderClass,
  id,
}) => {
  return (
    <Box
      className={`button-holder ${holderClass ? holderClass : ""}`}
      sx={{ ...holderStyles }}
    >
      <Button
        id={id}
        variant="contained"
        size="large"
        type={type ? type : "button"}
        sx={{ ...buttonStyles }}
        onClick={onClickContinue}
      >
        {label}
      </Button>
    </Box>
  );
};

export default OneButtonHolder;
