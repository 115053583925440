import * as React from "react";
import { ContentWrapper } from "./ContentWrapper";
import theme from "../theme/theme";

const customStyle = {
  paddingBottom: "30px",
  [theme.breakpoints.up("md")]: {
    paddingBottom: "30px",
  },
  "& .main-image-wrapper": {
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
};

export default function StyledContentWrapperNoButton(props) {
  return (
    <ContentWrapper {...props} sx={{ ...customStyle }}>
      {props.children}
    </ContentWrapper>
  );
}
