const STEP_ID = {
  // Overdraft
  JourneyNotStarted: 1,
  LandingPage: 100,
  LandingPageAlternativeBeforeLoanPreview: 101,
  LandingPageAlternativeAfterLoanPreview: 102,
  LandingPageAlternativeAfterLoanPreviewCodebtor: 103,
  LandingPageAlternativePartnerNoRepresentative: 104,
  LandingPageAlternativePartnerCodebtor: 105,
  LandingPageAlternativeAfterLoanPreviewCodebtorMandatory: 106,
  LoanParameters: 200,
  StandardEditLoanParameters: 210,
  Declarations: 300,
  CCRValidationsWait: 310,
  CCRValidationsDropped: 311,
  BankAccounts: 400,
  AccountValidationDropped: 401,
  BankAccountsValidationWait: 402,
  LoanPreview: 500,
  LoanPreviewAlternative: 501,
  LoanPreviewAlternativeCodebtor: 502,
  CodebtorDeclaration: 510,
  CodebtorCCRValidationsWait: 511,
  CodebtorCCRValidationsDropped: 512,
  Codebtors: 550,
  CodebtorsMandatory: 551,
  Documents: 600,
  DocumentsParameters: 601,
  DocumentsWait: 602,
  OnlineValidationsWait: 700,
  OnlineValidationsDropped: 701,
  ExistingOverdraftDropped: 702,
  DocumentsSignature: 800,
  DocumentsSignatureParametersWait: 801, //Необходимо е вашият съдружник да потвърди подписването на документите (pred potpishuvanje so euroturst, moze na dvata)
  DocumentsSignatureOTPWait: 810, // Ще можете да продължите с подписването на документите, след, като вашия съдружник премине през процеса на подписване (pred otp samo za vtoriot koga go ceka prviot)
  DocumentsSignatureOTP: 820,
  DocumentsSignatureWait: 821,
  DocumentsSignatureFailure: 822,
  UnsuccessfulDocumentSigning: 823,
  DocumentsSignatureDeniedAlternative: 824,
  DocumentsSignatureWaitAlternative: 825,
  FinalPage: 900,

  // Term Loan
  TermLoanLandingPage: 110,
  TermLoanLandingPageCodebtor: 111,
  TermLoanLandingPageCodebtorMandatory: 112,
  TermLoanLandingPagePartnerLoanSuccess: 113,
  TermLoanParameters: 201,
  TermLoanEditLoanParameters: 211,
  TermLoanLoanPreview: 520,
  TermLoanLoanPreviewCodebtor: 521,
  TermLoanSuccessPreviewPartner: 522,
  TermLoanDeclarations: 301,

  // Credit Line
  CreditLineLandingPage: 120,
  CreditLineLandingPageCodebtor: 121,
  CreditLineLandingPageCodebtorMandatory: 122,
  CreditLineLandingPagePartner: 123,
  CreditLineLoanParameters: 202,
  CreditLineEditLoanParameters: 212,
  CreditLineDeclarations: 302,
  CreditLineLoanPreview: 530,
  CreditLineLoanPreviewCodebtor: 531,
  CreditLineLoanPreviewPartner: 532,

  // Revolving
  JourneyExpired: 1000,
  RevolvingLandingPage: 10001,
  RevolvingLandingPageCodebtor: 10002,
  RevolvingOfferDeclined: 10101,
  RevolvingOfferDeclinedAlternativeCodebtor: 10102,
  RevolvingOfferAcceptedAlternativeCodebtor: 10202,
  RevolvingOfferAcceptedLoanParametars: 10203,
  RevolvingDocumentsParameters: 10301,
  RevolvingDocuments: 10302,
  RevolvingDocumentsWait: 10303,
  RevolvingDocumentsSignitureParametars: 10304,
  RevolvingDocumentsSignitureParametarsWait: 10305,
  RevolvingDocumentsSignitureOTPWait: 10306,
  RevolvingDocumentsSignitureOTP: 10307,
  RevolvingDocumentsSignitureWait: 10308,
  RevolvingDocumentsSignitureWaitAlternative: 10309,
  RevolvingDocumentsSignitureDenied: 10310,
  RevolvingDocumentsSignitureFailure: 10311,
  RevolvingDocumentsSignitureFailureAlternative: 10312,
  RevolvingOnlineValidationsWait: 10313,
  RevolvingOnlineValidationsDropped: 10314,
  RevolvingOnlineValidationsDroppedActualState: 10315,
  RevolvingOnlineValidationsDroppedPersonalIdentity: 10316,
  RevolvingOnlineValidationsDroppedCircumstances: 10317,
  RevolvingFinalPage: 10401,
  RevolvingFinalPageCalculator: 10402
};
export default STEP_ID;

// Journey Step			Api Call - On Load	Api Call - Option Continue
// 100	Начална страница	LandingPage		POST loan/step/forward
// 101	Начална страница - Преди одобрение	LandingPageAlternativeBeforeLoanPreview (case1) - ako prviot samo sto pocnal
// 102	Начална страница - След одобрение	LandingPageAlternativeAfterLoanPreview (case3)		POST loan/step/forward ako prviot kliknal ne
// 103	Начална страница - След одобрение - Съдлъжник	LandingPageAlternativeAfterLoanPreviewCodebtor(case2)		POST loan/step/forward ako prviot kliknal da
// 104  LandingPageAlternativePartnerNoRepresentative
// 200	Избор на параметри	LoanParameters		POST loan/parameters
// 300	Кандидатстване за кредит - Съгласие	Declarations		POST loan/declarations
// 310	Кандидатстване за кредит - Валидации - Изчакване	CCRValidationsWait
// 311	Кандидатстване за кредит - Валидации - Неуспех	CCRValidationsDropped
// 400	Кандидатстване за кредит - Избор на сметка	BankAccounts	GET loan/bank-accounts	POST loan/bank-accounts
// 401	Кандидатстване за кредит - Валидация сметки - Неуспех	BankAccountsValidationsDropped
// 402 -BankAccountsValidationWait - Waiting for the bank accounts validation
// 500	Кандидатстване за кредит - Преглед на кредита	LoanPreview		POST loan/step/forward
// 501	Кандидатстване за кредит - Преглед на кредита - Съсобственик	LoanPreviewAlternative (case3 pregled na kredit bez napred button)
// 502	Кандидатстване за кредит - Преглед на кредита - Съдлъжник	LoanPreviewAlternativeCodebtor (case 2 pregled na kredit ima napred, ist screen kako toj sto go imame)
// 550	Кандидатстване за кредит - Съдлъжник	Codebtors (prv korisnik dali saka owner 2)		POST loan/codebtors
// 600	Документи	Documents	GET documents	POST loan/step/forward
// 601	Документи - Параметри	DocumentsParameters (noviot screen so email I phone)		POST documents/parameters
// 602	Документи - Изчакване	DocumentsWait (noviot screen pred dokumenti za prv owner, moze I za vtor da e)
// 700	Валидации - Изчакване	OnlineValidationsWait
// 701	Валидации - Неуспех	OnlineValidationsDropped
// 800	Документи - Подписване - Параметри	DocumentsSignatureParameters (eurotrust samo so telefon)		POST documents/sign
// 801	Документи - Подписване - Параметри - Изчакване	DocumentsSignatureParametersWait (nov ekran kade cekame I vtoriot da pomine kon toj ekran, Необходимо е вашият съдружник да потвърди подписването на документите)
// 810	Документи - Подписване - OTP - Изчакване	DocumentsSignatureOTPWait
// 820	Документи - Подписване - OTP	DocumentsSignatureOTP		"POST documents/sign/otp/send
// POST documents/sign/otp/resend"
// 821	Документи - Подписване - Изчакване	DocumentsSignatureWait
// 822	Документи - Подписване - Прекратяване	DocumentsSignatureDenied (ova e koga ima restart posle otp)		POST documents/sign/restart
// 823	Документи - Подписване - Неуспех	DocumentsSignatureFailure
// 824	Документи - Подписване - Прекратяване (без рестартиране)	DocumentsSignatureDeniedAlternative (posle otp ama nema restart)
// 825 -N'Документи - Подписване - Изчакване (вкл. съдлъжник)', 'DocumentsSignatureWaitAlternative'
// 900	Приключване	FinalPage
