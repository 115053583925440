import { useSelector } from "react-redux";

const useCalculatorData = () => {
  const allLoanParams = useSelector((state) => state.calculator.loanParams);

  const loan = useSelector(
    (state) => state.calculator.loanParams.sliderLoanValue
  );

  const duration = useSelector(
    (state) => state.calculator.loanParams.sliderDurationValue
  );

  const repayment = useSelector(
    (state) => state.calculator.loanParams.sliderRepaymentValue
  );

  const interestRate = useSelector(
    (state) => state.calculator.loanParams.interestRate
  );
  const loanParamsSaved = useSelector(
    (state) => state.calculator.loanParamsSaved
  );
  const sliderGraceValue = useSelector(
    (state) => state.calculator.loanParams.sliderGraceValue
  );

  const calculatorStatus = useSelector(
    (state) => state.calculator.calculatorStatus
  );
  const currencyType = useSelector(
    (state) => state.calculator.loanParams.currency
  );
  const withCalculator = useSelector(
    (state) => state.calculator.loanParams.withCalculator
  );

  return {
    allLoanParams,
    loan,
    duration,
    interestRate,
    calculatorStatus,
    loanParamsSaved,
    currencyType,
    repayment,
    withCalculator,
    sliderGraceValue
  };
};

export default useCalculatorData;
