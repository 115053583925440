import { Box, Typography } from "@mui/material";
import CircularMultiSpinner from "../svgicons/CircularMultiSpinner";
import StyledContentWrapper from "../../styledComponents/ContentWrapper";
import theme from "../../theme/theme";
import React from "react";

const LoadingScreen = ({
  title,
  description,
  additionalText,
  additionalDescription,
  additionalBoldText,
}) => {
  return (
    <StyledContentWrapper>
      <Box className="loading-page">
        <Typography component="div">
          <Typography gutterBottom variant="h4" component="div" sx={{ mb: 6 }}>
            {title}
          </Typography>
        </Typography>
        <CircularMultiSpinner />
        {description && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              mt: 6,
              maxWidth: "360px",
              textAlign: "center",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "24px",
              marginTop: "20px",
              [theme.breakpoints.up("md")]: {
                maxWidth: "630px",
              },
            }}
          >
            {description}
          </Typography>
        )}
        {additionalText && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              mt: 6,
              maxWidth: "360px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "24px",
              marginTop: "30px",
              [theme.breakpoints.up("md")]: {
                maxWidth: "100%",
              },
            }}
          >
            Обработката може да отнеме няколко минути.
          </Typography>
        )}
        {additionalBoldText && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              mt: 6,
              maxWidth: "430px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "24px",
              marginTop: "30px",
              [theme.breakpoints.up("md")]: {
                maxWidth: "550px",
              },
            }}
          >
            {additionalBoldText}
          </Typography>
        )}
        {additionalDescription && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              mt: 6,
              maxWidth: "250px",
              textAlign: "center",
              fontWeight: "normal",
              fontSize: "18px",
              lineHeight: "24px",
              marginTop: "30px",
              [theme.breakpoints.up("md")]: {
                maxWidth: "630px",
              },
            }}
          >
            {additionalDescription}
          </Typography>
        )}
      </Box>
    </StyledContentWrapper>
  );
};

export default LoadingScreen;
