import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchLoan,
  fetchIdentityChalange,
  postCodebtors,
} from "../../providers/application/applicationApi";
import { parsePhoneNumber } from "react-phone-number-input/mobile";
import { STATUS } from "../../constants/constants";
import {
  setStep,
  setStepRequiresRefresh,
  setStepStatus,
} from "../step/stepSlice";

export const identityChallenge = createAsyncThunk(
  "application/identityChallenge",
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await fetchIdentityChalange(uuid);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }

      return await response.json();
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getLoan = createAsyncThunk(
  "application/fetchApplicationState",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchLoan(id);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      dispatch(setStepStatus(STATUS.SUCCEEDED));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const getDeclarationForCoBorrowers = createAsyncThunk(
  "application/fetchDeclarationForCoBorrowers",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchLoan(id);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

export const saveCodebtors = createAsyncThunk(
  "application/saveCodebtors",
  async (codebtor, { rejectWithValue, dispatch }) => {
    try {
      const response = await postCodebtors(codebtor);
      if (!response.ok) {
        rejectWithValue(response.data.message);
      }
      var result = await response.json();
      dispatch(setStep(result.journeyStepId));
      dispatch(setStepRequiresRefresh(result.journeyStepRequiresRefresh));
      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error);
    }
  }
);

/// Default initial state object
const initialState = {
  applicationState: {
    uuid: "",
    accountIBAN: "",
    dateInitialContractMaturityDate: "",
    dateInitialContract: "",
    hierarchyMaxAmount: "",
    companyName: "",
    status: "",
    customer: {
      namePrefix: "string",
      fullName: "string",
      lastName: "string",
      email: "string",
      phone: "string",
    },
    codebtors: [],
    representative: {
      namePrefix: "string",
      fullName: "string",
      lastName: "string",
      email: "string",
      phone: "string",
    },
    declarations: {
      withDeclarationForCoBorrowers: false,
    },
    documents: {
      withSignatureOTPResend: false,
    },
    errorCode: 0,
    errorDescription: "",
    productId: "",
  },
  authenticated: false,
  status: STATUS.IDLE,
  imagesPreloading: true,
  declarationStatus: STATUS.IDLE,
  firstLoad: true,
  ticket: null,
  savePhoneStatus: STATUS.IDLE,
  saveEmailStatus: STATUS.IDLE,
  savePhoneError: null,
  saveEmailError: null,
  error: null,
  showGeneralError: false,
  secondOwner: false,
  secondOwnerContinue: false,
  codebtorStatus: STATUS.IDLE,
};

/// Calculator slice
export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setphone(state, action) {
      state.applicationState.customer.phone = action.payload;
      state.applicationState.customer.phone.replaceAll(" ", "");
      var phone = action.payload.replaceAll(" ", "");

      if (phone !== undefined && phone !== "" && phone !== null) {
        state.applicationState.customer.phone = formatPhoneNumber(phone);
      }
    },
    setImagesLoading(state, action) {
      state.imagesPreloading = action.payload;
    },
    setEmail(state, action) {
      state.applicationState.customer.email = action.payload;
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    setShowGeneralError(state, action) {
      state.showGeneralError = action.payload;
    },
    setDeclarationStatus(state, action) {
      state.declarationStatus = action.payload;
    },
    setWithSignatureOTPResend(state, action) {
      state.applicationState.documents.withSignatureOTPResend = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoan.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getLoan.fulfilled, (state, action) => {
        const applicationState = action.payload.data;
        state.status = STATUS.SUCCEEDED;
        state.declarationStatus = STATUS.SUCCEEDED;
        state.firstLoad = false;
        state.applicationState.uuid = applicationState.uuid;
        state.applicationState.accountIBAN = applicationState.accountIBAN;
        state.applicationState.dateInitialContractMaturityDate = applicationState.dateInitialContractMaturityDate;
        state.applicationState.dateInitialContract = applicationState.dateInitialContract;
        state.applicationState.hierarchyMaxAmount = applicationState.hierarchyMaxAmount;
        state.applicationState.productId = applicationState.parameters.productId;
        state.applicationState.companyName = applicationState.companyName;
        state.applicationState.status = applicationState.status;
        state.applicationState.customer = applicationState.customer;
        state.applicationState.codebtors = applicationState.codebtors;
        state.applicationState.representative = applicationState.representative;
        state.applicationState.declarations = applicationState.declarations;
        state.applicationState.documents = applicationState.documents;
        var phone = applicationState.customer.phone;
        state.applicationState.customer.phone = phone;
        if (phone !== undefined && phone !== null) {
          phone = phone.replaceAll(" ", "");
          if (phone !== "") {
            state.applicationState.customer.phone = formatPhoneNumber(phone);
          } else {
            state.applicationState.customer.phone = phone;
          }
        }
      })
      .addCase(getLoan.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(getDeclarationForCoBorrowers.pending, (state) => {
        state.declarationStatus = STATUS.LOADING;
      })
      .addCase(getDeclarationForCoBorrowers.fulfilled, (state, action) => {
        const applicationState = action.payload.data;
        state.declarationStatus = STATUS.SUCCEEDED;
        state.applicationState.declarations = applicationState.declarations;
        state.applicationState.documents = applicationState.documents;
        state.applicationState.documents.withSignatureOTPResend =
          applicationState.documents.withSignatureOTPResend;
      })
      .addCase(getDeclarationForCoBorrowers.rejected, (state, action) => {
        state.declarationStatus = STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(identityChallenge.fulfilled, (state, action) => {
        state.authenticated = true;
      })
      .addCase(identityChallenge.rejected, (state, action) => {
        state.authenticated = false;
      })
      .addCase(saveCodebtors.pending, (state, action) => {
        state.codebtorStatus = STATUS.LOADING;
      })
      .addCase(saveCodebtors.fulfilled, (state, action) => {
        state.codebtorStatus = STATUS.SUCCEEDED;
      })
      .addCase(saveCodebtors.rejected, (state, action) => {
        state.codebtorStatus = STATUS.FAILED;
      });
  },
});

/// Get all actions
export const {
  setphone,
  setEmail,
  setAuthenticated,
  setShowGeneralError,
  setDeclarationStatus,
  setImagesLoading,
  setWithSignatureOTPResend,
} = applicationSlice.actions;

export default applicationSlice.reducer;

export const formatPhoneNumber = (phoneNum) => {
  var parseMobileNumber = parsePhoneNumber(phoneNum, "BG");

  var internationalNumberConvert = parseMobileNumber
    ? parseMobileNumber.formatInternational()
    : "";
  return internationalNumberConvert;
};
