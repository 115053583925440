import { theme } from "../../theme/theme";

const styles = {
	holder: {
		[theme.breakpoints.down("md")]: {
			display: "none",
			padding: 0,
			margin: 0,
		},
		maxWidth: "100vw",
		justifyContent: "center",
		overflow: "hidden",
		position: "relative",
		height: "106px",
		borderTopLeftRadius: "50px",
		borderTopRightRadius: "50px",
		backgroundColor: [theme.palette.primary.main],
		display: "flex",
		alignItems: "center",
		padding: 0,
		margin: 0,
		[theme.breakpoints.down("lg")]: {
			height: "146px",
			maxWidth: "100vw",
			padding: 0,
			margin: 0,
		},
		[theme.breakpoints.up("lg")]: {
			height: "106px",
			maxWidth: "100vw",
			padding: 0,
			margin: 0,
		},
	},
	content: {
		color: theme.palette.others.white,
		maxWidth: "1136px",
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		padding: 0,
		margin: 0,
		[theme.breakpoints.up("lg")]: {
			flexDirection: "row",
		},
	},
	content_left: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
	},
	content_right: {
		letterSpacing: "0px",
		textAlign: "left",
		color: theme.palette.others.white,
		fontSize: "14px",
		paddingTop: "20px",
		[theme.breakpoints.up("lg")]: {
			textAlign: "right",
			paddingTop: "0px",
		},
	},
	logo_holder: {
		borderWidth: "0 1px 0 0",
		borderColor: theme.palette.others.white,
		borderStyle: "solid",
		height: "35px",
		width: "140px",
		paddingRight: "30px",
		marginRight: "30px",
		display: "flex",
		alignItems: "center",
		paddingTop: "5px",
	},
	otp_holder: {
		paddingTop: "5px",
		paddingLeft: "15px",
	},
};
export default styles;
