export function fetchBankAccountsRequest() {
  const fetchBankAccountsRequestOptions = {
    method: "GET",
  };

  const bankAccountsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/bank-accounts`;
  return fetch(bankAccountsUrl, fetchBankAccountsRequestOptions);
}

export function saveBankAccountsRequest(bankAccount) {
  const saveBankAccountsRequestOptions = {
    method: "POST",
    body: JSON.stringify(bankAccount),
  };

  const bankAccountsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/bank-accounts`;
  return fetch(bankAccountsUrl, saveBankAccountsRequestOptions);
}
