import React, { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter basename={baseUrl}>
      <Provider store={store}>
        <App tab="/" />
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
