import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SmallPhoneIconImg } from "../../assets/svgicons/OTP-icon-24x24-telephone.svg";

const SmallPhoneIcon = (props) => {
  return (
    <SvgIcon component={SmallPhoneIconImg} {...props} viewBox="0 0 24 24" />
  );
};

export default SmallPhoneIcon;
