import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Grid, Box } from "@mui/material";
import Progress1 from "../svgicons/progress-bar/progress-1";
import Progress2 from "../svgicons/progress-bar/progress-2";
import Progress3 from "../svgicons/progress-bar/progress-3";
import Progress4 from "../svgicons/progress-bar/progress-4";
import Progress5 from "../svgicons/progress-bar/progress-5";
import Progress6 from "../svgicons/progress-bar/progress-6";
import Progress7 from "../svgicons/progress-bar/progress-7";
import Progress8 from "../svgicons/progress-bar/progress-8";
import Progress9 from "../svgicons/progress-bar/progress-9";
import Progress10 from "../svgicons/progress-bar/progress-10";
import Progress_3_1 from "../svgicons/progress-bar/progress-3-1";
import Progress_3_2 from "../svgicons/progress-bar/progress-3-2";
import Progress_3_3 from "../svgicons/progress-bar/progress-3-3";
import Progress_3_4 from "../svgicons/progress-bar/progress-3-4";
import Progress_3_5 from "../svgicons/progress-bar/progress-3-5";
import CallCenterIcon from "../svgicons/CallCenterIcon";
import styles from "./page-progress-styles.js";
import { ROUTE_PATHS } from "../../constants/constants";
import useCalculatorData from "../../hooks/useCalculatorData";

const mappedPagesProgressImages = [
  {
    paths: [
      ROUTE_PATHS.Calculator,
      ROUTE_PATHS.CalculatorTermLoan,
      ROUTE_PATHS.CalculatorCreditLine,
    ],
    progressImage: Progress1,
    title: "1. Избор на параметри",
  },
  {
    paths: [ROUTE_PATHS.RevolvingOfferAcceptedLoanParametars],
    progressImage: Progress1,
    title: "1. Избор на сума",
  },
  {
    paths: [ROUTE_PATHS.AcceptAgreement],
    progressImage: Progress2,
    title: "2. Кандидатстване за кредит",
  },
  {
    paths: [ROUTE_PATHS.AcceptAgreementApproved],
    progressImage: Progress3,
    title: "2. Кандидатстване за кредит",
  },
  {
    paths: [
      ROUTE_PATHS.CreditOverview,
      ROUTE_PATHS.TermLoanCreditOverview,
      ROUTE_PATHS.CreditLineLoanPreview,
      ROUTE_PATHS.GeneralTermsViewCreditOverview,
      ROUTE_PATHS.StandardEditLoanParameters,
      ROUTE_PATHS.CreditLineEditLoanParameters,
      ROUTE_PATHS.CreditLineLoanPreviewCodebtor
    ],
    progressImage: Progress4,
    title: "2. Кандидатстване за кредит",
  },

  {
    paths: [
      ROUTE_PATHS.AddSecondOwner, 
      ROUTE_PATHS.SecondOwnerMandatory,
      ROUTE_PATHS.AcceptAgreementCodebtor],
    progressImage: Progress5,
    title: "2. Кандидатстване за кредит",
  },
  {
    paths: [ROUTE_PATHS.RevolvingDocumentsParameters],
    progressImage: Progress5,
    title: "2. Актуализация на данни",
  },

  {
    paths: ["/WaitingSecondOwner"],
    progressImage: Progress6,
    title: "3. Документи",
  },
  {
    paths: [ROUTE_PATHS.ProvidePhoneEmail],
    progressImage: Progress6,
    title: "3. Документи",
  },
  {
    paths: [
      ROUTE_PATHS.FinaliseOfferDocuments,
      ROUTE_PATHS.FinaliseOfferDocumentsDeclined,
      ROUTE_PATHS.LoanContractView,
      ROUTE_PATHS.TermLoanContractView,
      ROUTE_PATHS.DeclarationView,
      ROUTE_PATHS.LoanApplicationView,
      ROUTE_PATHS.GeneralTermsView,
      ROUTE_PATHS.GeneralTermsEvrotrustView,
      ROUTE_PATHS.OverdraftAnexView,
      ROUTE_PATHS.SendLoanApplicationEmail,
      ROUTE_PATHS.SendLoanContractEmail,
      ROUTE_PATHS.SendDeclarationsEmail,
      ROUTE_PATHS.SendOverdraftAnexEmail,
      ROUTE_PATHS.RevolvingDocuments,
    ],
    progressImage: Progress7,
    title: "3. Документи",
  },
  {
    paths: [ROUTE_PATHS.PreConfirmUserInfo],
    progressImage: Progress8,
    title: "4. Подписване", //novo
  },
  {
    paths: [
      ROUTE_PATHS.EurotrustPhoneOtpValidation,
      ROUTE_PATHS.UnsuccessfulDocumentSigning,
      ROUTE_PATHS.FinaliseOfferDeclinedSecondOwner,
    ],
    progressImage: Progress9,
    title: "4. Подписване",
  },
  {
    paths: [
      ROUTE_PATHS.FinaliseOfferSuccess,
      ROUTE_PATHS.FinaliseOfferDeclined,
      ROUTE_PATHS.RevolvingOfferDeclined,
      ROUTE_PATHS.RevolvingFinalPage,
      ROUTE_PATHS.RevolvingFinalPageCalculator
    ], //novo
    progressImage: Progress10,
    title: "Приключване",
  },
];

const mappedPagesProgressImagesNoCalculator = [
  {
    paths: [ROUTE_PATHS.RevolvingDocumentsParameters],
    progressImage: Progress_3_1,
    title: "1. Актуализация на данни",
  },
  {
    paths: [ROUTE_PATHS.RevolvingDocuments],
    progressImage: Progress_3_2,
    title: "2. Документи",
  },
  {
    paths: [ROUTE_PATHS.PreConfirmUserInfo],
    progressImage: Progress_3_3,
    title: "3. Подписване",
  },
  {
    paths: [
      ROUTE_PATHS.EurotrustPhoneOtpValidation,
      ROUTE_PATHS.UnsuccessfulDocumentSigning,
      ROUTE_PATHS.FinaliseOfferDeclinedSecondOwner,
    ],
    progressImage: Progress_3_4,
    title: "3. Подписване",
  },
  {
    paths: [ROUTE_PATHS.RevolvingFinalPage, ROUTE_PATHS.RevolvingOfferDeclined],
    progressImage: Progress_3_5,
    title: "Приключване",
  },
];

const PageProgressBar = () => {
  const { pathname } = useLocation();
  const { withCalculator } = useCalculatorData();

  const pageDataElement = useMemo(() => {
    let pageProgressImages = [];
    if (withCalculator) {
      pageProgressImages = mappedPagesProgressImages.slice();
    } else {
      pageProgressImages = mappedPagesProgressImagesNoCalculator.slice();
    }
    const foundIndex = pageProgressImages.findIndex((element) =>
      element.paths.includes(pathname)
    );
    return foundIndex >= 0 ? pageProgressImages[foundIndex] : null;
  }, [pathname, withCalculator]);

  return pageDataElement ? (
    <Box sx={{ ...styles }}>
      <Box className="content">
        <Grid container className="grid">
          <Box></Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {pageDataElement.title}
          </Typography>
          <CallCenterIcon cs="only_mobile" />
        </Grid>
        {<pageDataElement.progressImage style={{ width: "100%" }} />}
      </Box>
    </Box>
  ) : null;
};

export default PageProgressBar;
