import React from "react";
import HeaderImageTop from "../../assets/pngs/header-image.png";
import Box from "@mui/material/Container";
import { theme } from "../../theme/theme";
import { useDispatch } from "react-redux";
import { setImagesLoading } from "../../providers/application/applicationSlice";

const TopBarImageDesktop = (props) => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        maxWidth: "100vw",
        [theme.breakpoints.up("lg")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <img
        src={HeaderImageTop}
        alt="HeaderTopImage"
        onLoad={() => dispatch(setImagesLoading(false))}
      />
    </Box>
  );
};

export default TopBarImageDesktop;
