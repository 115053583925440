import { theme } from "../../theme/theme";

const styles = {
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    padding: "11px",
  },
  desktop: {
    container: {
      height: "238px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        padding: "0px",
        margin: "0px",
        maxWidth: "100vw",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "0px",
        margin: "0px",
        maxWidth: "100vw",
      },
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      maxWidth: "100vw",
    },
    content: {
      maxWidth: "1136px",
      margin: "auto",
      position: "relative",
      height: "238px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        maxWidth: "1136px",
        padding: "0px 30px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "1136px",
        padding: "0px",
      },
    },
    title: {
      letterSpacing: "0px",
      textAlign: "left",
      color: theme.palette.others.white,
      textShadow: "0px 2px 5px rgba(0, 0, 0, 0.7)",
    },
    number: {
      display: "inline-block",
      width: "218px",
      height: "30px",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "24px",
      right: "30px",
      top: "21px",
      color: theme.palette.others.white,
      position: "absolute",
      padding: "0px",
      [theme.breakpoints.up("md")]: {
        padding: "0px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "0px",
        right: "0px",
      },
    },
    phoneIcon: {
      lineHeight: "22px",
      verticalAlign: "bottom",
      paddingLeft: "7px",
      paddingRight: "3px",
    },
  },
};
export default styles;
