import { theme } from "../../theme/theme";

const styles = {
	marginBottom: "30px",
	[theme.breakpoints.up("md")]: {
		marginBottom: "70px",
		height: "84px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.others.white,
		borderRadius: "25px",
		marginTop: "-50px",
		zIndex: 1,
		"& .content": {
			maxWidth: "600px",
		},
	},
	"& .grid": {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.up("md")]: {
			justifyContent: "center",
		},
	},
	"& .only_mobile": {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
};

export default styles;
