import { useSelector } from "react-redux";

const useStepData = () => {
  const currentStep = useSelector((state) => {
    return state.step.step;
  });
  const stepStatus = useSelector((state) => {
    return state.step.stepStatus;
  });
  const waitingMode = useSelector((state) => {
    return state.step.waitingMode;
  });
  const waitModeNumFails = useSelector((state) => {
    return state.step.waitModeNumFails;
  });

  const journeyStepRequiresRefresh = useSelector((state) => {
    return state.step.journeyStepRequiresRefresh;
  });

  return {
    currentStep,
    stepStatus,
    waitingMode,
    waitModeNumFails,
    journeyStepRequiresRefresh,
  };
};

export default useStepData;
