import * as React from "react";
import { styled } from "@mui/material/styles";

const MainBackground = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.others.white,
	[theme.breakpoints.up("md")]: {
		backgroundColor: "#E5E5E5",
	},
}));

export default function StyledMainBackground(props) {
	const children = props.children;
	return <MainBackground>{children}</MainBackground>;
}
