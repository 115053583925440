export function fetchDocuSignOtpSend(documentSignData) {
  const documentSignOptions = {
    method: "POST",
    body: JSON.stringify(documentSignData),
  };

  const documentSignUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/sign/otp/send`;
  return fetch(documentSignUrl, documentSignOptions);
}

export function fetchDocuSignOtpResend(documentSignData) {
  const documentSignOptions = {
    method: "POST",
    body: JSON.stringify(documentSignData),
  };

  const documentSignUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/sign/otp/resend`;
  return fetch(documentSignUrl, documentSignOptions);
}

export function fetchDocuSignBegin(documentSignData) {
  const documentSignOptions = {
    method: "POST",
    body: JSON.stringify(documentSignData),
  };

  const documentSignUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/sign`;
  return fetch(documentSignUrl, documentSignOptions);
}
export function docSignRestart() {
  const documentSignOptions = {
    method: "POST",
  };

  const documentSignUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/documents/sign/restart`;
  return fetch(documentSignUrl, documentSignOptions);
}
