export function fetchLoan() {
  const loanRequestOptions = {
    method: "GET",
  };
  const loanUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan`;
  return fetch(loanUrl, loanRequestOptions);
}

export function fetchIdentityChalange(uuid) {
  const getIdentityChalangeRequestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const getIdentityChallengeUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/identity/challenge?uuid=${uuid}`;
  return fetch(getIdentityChallengeUrl, getIdentityChalangeRequestOptions);
}

export function postCodebtors(withoutCodebtors) {
  const codebtorsRequestOptions = {
    method: "POST",
    body: JSON.stringify({ withoutCodebtors: withoutCodebtors }),
  };
  const codebtorsUrl = `${process.env.REACT_APP_APPLICATION_API_URI}/loan/codebtors`;
  return fetch(codebtorsUrl, codebtorsRequestOptions);
}
