import * as Constants from "../../helpers/calculator/constants";
import { formatAmount } from "../utils";

/// По подадени параметри намапва и връща подходящ обект за Slider
const mapSliderData = (type, value, step, min, max, currency) => {
  var sliderData = {
    value: value,
    step: step,
    min: min,
    max: max,
    marks: getSliderMarksByType(type)(min, max, currency),
  };

  return sliderData;
};

/// По подаден тип връща метода за констроиране на маркс за съответния Slider
const getSliderMarksByType = (type) => {
  switch (type) {
    case Constants.LoanAmount:
      return getLoanAmountSliderMarks;
    case Constants.LoanDuration:
      return getLoanDurationSliderMarks;
    case Constants.ExpiryRepayment:
      return getExpiryRepaymnetSliderMarks;
    default:
      return [];
  }
};

/// По подадени параметри калкулира increment стъпката на Slider.
const calculateIncrementSlider = (value, step, max) => {
  var resultValue = value + step;

  if (resultValue <= max) {
    return resultValue;
  }

  return max;
};

/// По подадени параметри калкулира decrement стъпката на Slider.
const calculateDecrementSlider = (value, step, min, max) => {
  let stepMax = max;
  if ((max - min) % step > 0) {
    stepMax = max - ((max - min) % step);
  }
  if (value > stepMax) {
    return stepMax;
  }
  var resultValue = value - step;

  if (resultValue >= min) {
    return resultValue;
  }

  return min;
};

/// По подаден LoanAmount настройва marks
const getLoanAmountSliderMarks = (min, max, currency) => {
  return [
    {
      value: min,
      label: `${formatAmount(min)} ${currency}`,
    },
    {
      value: max,
      label: `${formatAmount(max)} ${currency}`,
    },
  ];
};

/// По подаден LoanDuration настройва marks
const getLoanDurationSliderMarks = (min, max) => {
  return [
    {
      value: min,
      label: `${min} Месеца`,
    },
    {
      value: max,
      label: `${max} Месеца`,
    },
  ];
};

/// По подаден ExpiryRepoaymnet настройва marks
const getExpiryRepaymnetSliderMarks = (min, max) => {
  return [
    {
      value: min,
      label: `${min} Месеца`,
    },
    {
      value: max,
      label: `${max} Месеца`,
    },
  ];
};

const changeInputValueAccordingToParams = (
  sliderLoanStep,
  sliderLoanMin,
  sliderLoanMax,
  val
) => {
  let changedVal = val;
  const diff = val % sliderLoanStep;
  const stepDiff = sliderLoanMax % sliderLoanStep;
  if (diff > 0 && diff >= sliderLoanStep / 2) {
    changedVal = val + (sliderLoanStep - diff);
  } else if (diff > 0 && diff < sliderLoanStep / 2) {
    changedVal = val - diff;
  }
  if (changedVal > sliderLoanMax || val >= sliderLoanMax - stepDiff / 2) {
    changedVal = sliderLoanMax;
  }
  if (changedVal < sliderLoanMin) {
    changedVal = sliderLoanMin;
  }
  return changedVal;
};

export {
  mapSliderData,
  getLoanAmountSliderMarks,
  getLoanDurationSliderMarks,
  getExpiryRepaymnetSliderMarks,
  calculateIncrementSlider,
  calculateDecrementSlider,
  changeInputValueAccordingToParams,
};
