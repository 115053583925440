import { createTheme } from "@mui/material/styles";

const colors = {
  primary_main: "#52ae30",
  secondary_main: "#006648",
  secondary: "#FF6600",
  btn_main: "#52ae30",
  white: "#ffffff",
  black: "#000000",
  blue: "#1B82E3",
  red: "#FF435A",
  card_content_gray: "#F4F4F4",
  card_header_gray: "#DDDCDC",
  modal_info: "#D2DCE6",
  slider_color: "#D0D8E8",
  slider_bg: "#52af77",
  slider_label: "#7D828B",
  radio_calculator: "#13B253",
  border_color: "#E3E8F1",
  doc_description: "#606060",
  holder_color: "#ECEFF6",
  light_text_color: "#c1c1c1",
  btn_disabled: "#DDDDDD",
  btn_disabled_text: "#ABABAB",
  btn_primary_main_hover: "#74bf1e",
  bg_green_holder: "#DCEFD6",
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 350,
    md: 768,
    lg: 1336,
    xl: 1920,
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary_main,
    },
    secondary: {
      main: colors.secondary_main,
      secondary: colors.secondary
    },
    others: {
      blue: colors.blue,
      white: colors.white,
      black: colors.black,
      red: colors.red,
      card_content_gray: colors.card_content_gray,
      card_header_gray: colors.card_header_gray,
      modal_info: colors.modal_info,
      slider_color: colors.slider_color,
      slider_bg: colors.slider_bg,
      slider_label: colors.slider_label,
      radio_calculator: colors.radio_calculator,
      border_color: colors.border_color,
      doc_description: colors.doc_description,
      holder_color: colors.holder_color,
      light_text_color: colors.light_text_color,
      bg_green_holder: colors.bg_green_holder,
    },
  },
  breakpoints: breakpoints,
  typography: {
    fontFamily: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji"`,
    h1: {
      fontFamily: `"Squad",ui-sans-serif,system-ui,-system-ui,-apple-system,BlinkMacSystemFont,
        "Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "48px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "32px",
    },
    h3: {
      fontFamily: `'Source Sans Pro', sans-serif`,
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "24px",
      lineHeight: "30px",
    },
    h4: {
      fontFamily: `'Source Sans Pro', sans-serif`,
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "24px",
      lineHeight: "30px",
    },
    h5: {
      fontFamily: `'Source Sans Pro', sans-serif`,
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "20px",
      lineHeight: "29px",
    },
    h6: {
      fontFamily: `'Source Sans Pro', sans-serif`,
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      textAlign: "center",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        borderRadius: 30,
        textTransform: "none",
        padding: ".75rem 2.5rem",
      },
      containedPrimary: {
        color: colors.white,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: colors.btn_primary_main_hover,
          boxShadow: "none",
        },
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
      label: {
        fontSize: "16px",
      },
    },
    MuiAutocomplete: {
      input: {
        "&:first-child": {
          padding: "9.5px 14px !important",
        },
      },
      clearIndicator: {
        display: "none",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 30,
        paddingLeft: "18px !important",
        paddingRight: "18px !important",
      },
    },
    PrivateNotchedOutline: {
      root: {
        paddingLeft: 25,
        paddingRight: 25,
      },
    },
    MuiInputLabel: {
      formControl: {
        left: 18,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 4px 40px #95959529",
      },
      elevation2: {
        boxShadow: "0 1rem 3rem rgba(73,92,136,0.15)",
      },
    },
    MuiStepIcon: {
      text: {
        fill: "#ffffff",
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: 0,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: "16px",
        fontWeight: 700,
        "&.MuiStepLabel-active": {
          fontWeight: 700,
        },
        "&.MuiStepLabel-completed": {
          fontWeight: 700,
        },
      },
      iconContainer: {
        paddingRight: "10px",
      },
    },
    MuiCardContent: {
      root: {
        padding: "16px 12px 16px 12px",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: "4px",
        paddingLeft: "14px",
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "start",
      },
    },
    MuiCheckbox: {
      root: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
      },
    },
    MuiRadio: {
      root: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 32,
      },
    },
    MuiChip: {
      root: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        backgroundColor: colors.white,
        color: colors.black,
      },
      deleteIcon: {
        color: colors.primary_main,
        "&:hover": {
          color: colors.btn_primary_main_hover,
        },
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          maxWidth: "355px",
          fontSize: "16px",
          padding: "7px 22px",
          cursor: "pointer",
          "&.Mui-disabled": {
            backgroundColor: colors.btn_disabled,
            color: colors.btn_disabled_text,
          },
        },
        containedPrimary: {
          backgroundColor: colors.btn_main,
          color: colors.white,
          textTransform: "none",
          borderRadius: "50px",
          width: "100%",
          marginTop: "24px",
          "&:hover": {
            backgroundColor: colors.btn_main,
          },
        },
        outlinedPrimary: {
          borderRadius: "50px",
          colore: "red",
          textTransform: "none",
          borderColor: colors.btn_main,
          color: colors.btn_main,
          marginTop: "24px",
          width: "100%",
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
          marginTop: "24px",
        },
        label: {
          fontSize: "16px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`@media (min-width:${breakpoints.values.md}px)`]: {
            maxWidth: "356px",
          },
          [`@media (min-width: 768px) and (max-width: 850px)`]: {
            maxWidth: "300px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: "calc(100vw - 60px)",
          [`@media (min-width:${breakpoints.values.md}px)`]: {
            minWidth: "600px",
            borderRadius: "24px",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "&.MuiGrid-item": { paddingTop: "0px" },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
        },
        h2: {
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
          margin: "30px 0",
          [`@media (min-width:${breakpoints.values.md}px)`]: {
            textAlign: "center",
          },
        },
        h3: {
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
          marginBottom: "20px",
          [`@media (min-width:${breakpoints.values.md}px)`]: {
            textAlign: "left",
          },
        },
        h4: {
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
          marginBottom: "30px",
          [`@media (min-width:${breakpoints.values.md}px)`]: {
            textAlign: "left",
          },
        },
        h5: {
          fontSize: "24px",
          lineHeight: "30px",
          textAlign: "center",
          marginBottom: "30px",
        },
        h6: {
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "center",
        },
        body1: {
          fontSize: "16px",
          lineHeight: "24px",
          marginBottom: "20px",
          textAlign: "left",
        },
        body2: {
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "left",
        },
        span: {
          fontSize: "14px",
          lineHeight: "16px",
          textAlign: "left",
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: "outlined",
      autoComplete: "off",
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiButton: {
      disableRipple: true,
      color: "danger",
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
});

export default theme;
