import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import STEP_ID from "../../helpers/steps";
import { setShowGeneralError } from "../../providers/application/applicationSlice";
import { getLoanStep, setStep } from "../../providers/step/stepSlice";

const useHandleCodes = () => {
  const [code, setCode] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      switch (code) {
        case 2025:
          dispatch(setStep(STEP_ID.JourneyExpired));
          break;
        case 2018:
          dispatch(getLoanStep());
          break;
        default:
          dispatch(setShowGeneralError(true));
          break;
      }
      setCode(null);
    }
  }, [code, dispatch]);

  return { setCode };
};

export default useHandleCodes;
