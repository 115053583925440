import { useSelector } from "react-redux";

const useApplicationData = () => {
  const companyName = useSelector(
    (state) => state.application.applicationState.companyName
  );

  const applicationStatus = useSelector((state) => state.application.status);
  const imagesPreloading = useSelector(
    (state) => state.application.imagesPreloading
  );

  const customer = useSelector(
    (state) => state.application.applicationState.customer
  );

  const codebtor = useSelector((state) =>
    state.application.applicationState.codebtors
      ? state.application.applicationState.codebtors[0]
      : null
  );

  const representative = useSelector((state) =>
    state.application.applicationState.representative
      ? state.application.applicationState.representative
      : null
  );

  const hasEmailSetup = useSelector(
    (state) =>
      state.application.applicationState.customer.email !== undefined &&
      state.application.applicationState.customer.email !== null &&
      state.application.applicationState.customer.email !== ""
  );

  const hasPhoneSetup = useSelector(
    (state) =>
      state.application.applicationState.customer.phone !== undefined &&
      state.application.applicationState.customer.phone !== null &&
      state.application.applicationState.customer.phone !== ""
  );

  const lastName = useSelector(
    (state) => state.application.applicationState.customer.lastName
  );

  const namePrefix = useSelector(
    (state) => state.application.applicationState.customer.namePrefix
  );

  const emailStatus = useSelector((state) => state.application.saveEmailStatus);

  const savePhoneStatus = useSelector(
    (state) => state.application.savePhoneStatus
  );

  const isAuthenticated = useSelector(
    (state) => state.application.authenticated
  );
  const generalErrorShown = useSelector(
    (state) => state.application.showGeneralError
  );
  const getUUID = useSelector(
    (state) => state.application.applicationState.uuid
  );
  const accountIBAN = useSelector(
    (state) => state.application.applicationState.accountIBAN
  );
  const withSignatureOTPResend = useSelector(
    (state) =>
      state.application.applicationState.documents.withSignatureOTPResend
  );
  const withDeclarationForCoBorrowers = useSelector(
    (state) =>
      state.application.applicationState.declarations
        .withDeclarationForCoBorrowers
  );
  const firstLoad = useSelector((state) => state.application.firstLoad);
  const declarationStatus = useSelector(
    (state) => state.application.declarationStatus
  );
  const codebtorStatus = useSelector(
    (state) => state.application.codebtorStatus
  );
  const secondOwner = useSelector((state) => state.application.secondOwner);
  const secondOwnerContinue = useSelector(
    (state) => state.application.secondOwnerContinue
  );
  const dateInitialContractMaturityDate = useSelector(
    (state) => state.application.applicationState.dateInitialContractMaturityDate
  );
  const dateInitialContract = useSelector(
    (state) => state.application.applicationState.dateInitialContract
  );
  const hierarchyMaxAmount = useSelector(
    (state) => state.application.applicationState.hierarchyMaxAmount
  );
  const productId = useSelector(
    (state) => state.application.applicationState.productId
  )

  return {
    isAuthenticated,
    customer,
    hasEmailSetup,
    hasPhoneSetup,
    lastName,
    emailStatus,
    namePrefix,
    savePhoneStatus,
    applicationStatus,
    imagesPreloading,
    generalErrorShown,
    getUUID,
    companyName,
    accountIBAN,
    withDeclarationForCoBorrowers,
    withSignatureOTPResend,
    firstLoad,
    declarationStatus,
    secondOwner,
    secondOwnerContinue,
    codebtorStatus,
    codebtor,
    representative,
    dateInitialContractMaturityDate,
    dateInitialContract,
    hierarchyMaxAmount,
    productId
  };
};

export default useApplicationData;