import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "1136px",
  height: "100%",
  width: "100%",
  backgroundColor: "transparent",
  paddingBottom: "80px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    padding: "30px",
    borderRadius: "25px",
    justifyContent: "center",
    paddingBottom: "80px",
  },
  "& .main-image-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 30px 0",
    maxWidth: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      textAlign: "center",
      margin: 0,
      marginTop: "56px",
    },
    "& img": {
      height: "154px",
      [theme.breakpoints.up("md")]: {
        height: "225px",
      },
    },
    "& img.codebtor": {
      height: "213px",
      [theme.breakpoints.up("md")]: {
        height: "290px",
      },
    },
  },
  "& .page-content-wrapper": {
    maxWidth: "850px",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  "& .content-wrapper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    flex: "1",
    [theme.breakpoints.up("md")]: {
      justifyContent: "start",
      width: "50%",
      maxWidth: "500px",
    },
  },
  "& .content-wrapper h1": {
    marginBottom: "24px",
    [theme.breakpoints.up("md")]: {
      marginTop: "45px",
      marginBottom: "24px",
    },
  },
  "& .content-wrapper .subtitle1": {
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
    marginTop: "20px",
    marginBottom: "45px",
  },
  "& .button-holder button, & .two-button-holder button": {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  "& .only_desktop, & .button-holder button.only_desktop, & .two-button-holder button.only_desktop":
    {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  "& .only_mobile": {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  "& .close_btn": {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  "& .back-btn, & .two-button-holder .back-btn": {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "124px",
      justifyContent: "space-between",
    },
  },
  "& .button-holder": {
    position: "absolute",
    width: "100%",
    bottom: 0,
    "&.relative_button": {
      position: "relative",
      marginTop: "60px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      left: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "356px",
      bottom: "20px",
    },
    "@media (min-width: 768px) and (max-width: 850px)": {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      left: 0,
    },
  },
  "& .twoBtnsImagePage-main-holder": {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
  "& .twoBtnsImagePage-content-holder": {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  "& .two-button-holder": {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      maxWidth: "760px",
      bottom: "20px",
      width: "auto",
      padding: "0 20px",
    },
  },
  "& .loading-page": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export default function StyledContentWrapper(props) {
  return <ContentWrapper {...props}>{props.children}</ContentWrapper>;
}
