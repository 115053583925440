const calculateInterestPayment = (loanAmount, interestRatePercentage) => {
  return loanAmount * interestRatePercentage * (30 / 36000);
};

const calculateFirstMonthPayment = (loanAmount, interestRatePercentage, loanDuration) => {
  return (loanAmount / (loanDuration - 1)) + (loanAmount * interestRatePercentage * (30 / 36000))
};

const calculateExpiryRepayment = (loanAmount, repaymentValue, interestRatePercentage) =>
  (loanAmount / repaymentValue) + (loanAmount * interestRatePercentage * (30 / 36000));

const calculateRegularPayments = (
  loanAmount,
  expiryRepayment,
  loanDuration,
  interestRatePercentage
) => {
  var amount = 0;
  if (expiryRepayment === 0) {
    return amount;
  } else {
    return (
      loanAmount / expiryRepayment +
      (loanAmount * interestRatePercentage) / loanDuration
    );
  }
};

const calculateMaintanceFee = (loanAmount) => {
  return loanAmount * 0.005;
};

export {
  calculateInterestPayment,
  calculateRegularPayments,
  calculateMaintanceFee,
  calculateFirstMonthPayment,
  calculateExpiryRepayment
};
